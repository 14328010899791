<template>
  <div>
    <profilo-utente v-if="step === PAGINA_PROFILO_UTENTE" @modificaUtente="modificaUtente"></profilo-utente>
    <modifica-utente v-if="step === PAGINA_MODIFICA_UTENTE" :assistito="jsonData.assistito" @saveEdit="saveEdit"></modifica-utente>
  </div>
</template>

<script>
import ModificaUtente from "./modificaUtente.vue";
import ProfiloUtente from "./profiloUtente.vue";

export default {
  components: {
    ProfiloUtente,
    ModificaUtente,
  },
  data() {
    return {
      jsonData: {
        contattoDaModificare: null,
      },
      PAGINA_PROFILO_UTENTE: 0,
      PAGINA_MODIFICA_UTENTE: 1,
      step: 0,
    };
  },
  methods: {
    setStep(step) {
      let me = this;
      me.step = step;
    },
    getStep() {
      let me = this;
      return me.step;
    },
    modificaUtente(item) {
      let me = this;
      me.jsonData.assistito = item;
      me.setStep(me.PAGINA_MODIFICA_UTENTE);
    },
    saveEdit() {
      let me = this;
      me.setStep(me.PAGINA_PROFILO_UTENTE);
    },
    onIndietro() {
      let me = this;
      me.setStep(me.PAGINA_PROFILO_UTENTE);
    },
  },
};
</script>
