<template>
  <b-modal
    id="modalPassword"
    class="modal-content"
    title="Recupera Password"
    slot="modal-header"
    hide-header-close
    @cancel="onClose"
    @ok="onInviaEmail"
    ok-title="Invia Email di Recupero"
    cancel-title="Chiudi"
  >
    <b-container>
      <b-row>
        <b-col>
          <input class="form-control form-control-sm" type="text" placeholder="Inserisci e-mail" name="user" required v-model="email" />
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import RecuperoCredenziali from '@/services/RecuperoCredenziali'
import strutture from "@/classes/strutture";
export default {
  data() {
    return {
      pathRestInviaEmail: "/api/v2.0/appecupt/auth/passwordsmarrita",
      email: null,
      token: null,
      tokenLogin: null,
    };
  },
  mounted: function () {
    this.token = localStorage.getItem("tokenWSO2");
    this.tokenLogin = localStorage.getItem("loginData");
  },
  methods: {
    onClose() {
      this.email = "";
      this.$bvModal.hide("modalPassword");
    },
    async onInviaEmail() {
      if (!this.email) {
        this.$bvModal.msgBoxOk("Attenzione, inserire una e-mail", {
          title: "Errore Invio E-mail",
          size: "md",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      } else {
        const response = await RecuperoCredenziali.cambioPasswordRichiesta(strutture[0].codice, this.email)
        if(!response.data.error){
          this.$bvModal.hide("modalPassword");
          this.$bvModal
              .msgBoxOk("Riceverà una e-mail con un pin per avviare la procedura di modifica password", {
                title: "Invio Riuscito!",
                size: "sm",
                okVariant: "outline-primary",
                headerClass: "vv-msg-header-primary",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {
                this.$router.push("/recuperapassword?email="+ this.email);
              })
              .catch((error) => {
                error;
              });
        } else {
          this.$bvModal.msgBoxOk(response.data.msg, {
            title: "Errore",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "vv-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        }
      }
    },
  },
};
</script>
