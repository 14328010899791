<template>
  <div>
    <div class="mt-15 container card_container">
      <div class="mb-5 titolo">
        <h3 class="text-white mt-5 text-center"><i class="fas fa-clipboard-list"></i> Pagamenti Da Effettuare</h3>
      </div>
      <b-container class="bv-example-row">
        <b-row class="my-5">
          <b-col sm="12" class="mt-5">
            <div class="h-35px">
              <strong class="text-primary">Assistito</strong>
            </div>
            <b-form-select class="form-select" v-model="jsonData" :options="assistitoOptions" value-field="value" text-field="text"></b-form-select>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="my-5 container card_container" v-if="jsonData.codiceFiscale != null">
      <b-card bg-variant="light">
        <b-container class="bv-example-row pt-lg-6">
          <b-row>
            <b-col sm class="mb-7">
              <span class="text-primary">Codice fiscale</span><br />
              <span>{{ jsonData.codiceFiscale }}</span>
            </b-col>
            <b-col sm class="mb-7">
              <span class="text-primary">Assistito</span><br />
              <span>{{ jsonData.nome }} {{ jsonData.cognome }}</span>
            </b-col>
            <b-col sm class="mb-7">
              <span class="text-primary">Data di nascita</span><br />
              <span>{{ jsonData.dataNascita }}</span>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </div>
    <div v-if="items.length === 0">
      <div class="mt-5 container card_container mb-5">
        <b-card bg-variant="light" class="mb-5">
          <b-container class="bv-example-row pt-lg-6">
            <b-row class="text-center">
              <b-col sm class="mb-7">
                <strong><span style="margin-left: 10px" class="text-center">Non ci sono appuntamenti</span></strong>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </div>
    </div>
    <div v-for="item in items" :key="item.idPrenotazione">
      <div class="mt-5 container card_container mb-5" v-if="jsonData.codiceFiscale">
        <b-card bg-variant="light" class="mb-5">
          <b-container class="bv-example-row pt-lg-6">
            <b-row>
              <b-col sm class="mb-7">
                <span class="text-primary">Struttura</span><br />
                <span>{{ strutturaPineta(item.codiceStruttura) }}</span>
              </b-col>
              <b-col sm class="mb-7">
                <span v-if="item.nre.match(/^\d{4}A\d{10}$/g) != null" class="text-primary">Codice impegnativa</span>
                <span v-else class="text-primary">Tipo Erogazione</span>
                <br />
                <span>{{ item.nre }}</span>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
        <div>
          <h5 v-if="!item.enable" class="text-black">
            Stato pagamento:
            <b-badge v-if="statiPagamentoNonDisp.indexOf(item.stato) > -1" class="text-primary">{{ item.stato }}</b-badge>
            <b-badge v-else class="text-green">{{ item.stato }}</b-badge>
          </h5>
          <b-button v-else class="mx-2 mb-2 btn btn-primary" @click="onPaga(item)"><i class="far fa-credit-card fs-2x"></i> Paga </b-button>

          <!-- <b-button v-if="item.enable" class="mx-2 mb-2 btn btn-primary" @click="onScaricaAvviso(item)" v-b-modal.pdfmodel>
            <i class="fas fa-file-download"></i> Scarica Avviso
          </b-button> -->
        </div>
        <div class="my-5">
          <datatable-lista-appuntamenti :appuntamenti="item.appuntamenti" :idModal="item.idPrenotazione"></datatable-lista-appuntamenti>
        </div>
        <b-card bg-variant="light" class="border-footer-card">
          <b-container class="bv-example-row">
            <b-row class="pt-lg-6">
              <b-col sm class="mb-7">
                <span class="text-primary">Tipo esenzione:</span>
                <span style="margin-left: 10px">N/D</span>
              </b-col>
              <b-col sm class="mb-7">
                <span class="text-primary">Importo:</span>
                <span style="margin-left: 10px">{{ item.importo }} €</span>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </div>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
    <pdf-viewer :pdf="jsonData.pdf" :title="title"></pdf-viewer>
  </div>
</template>
<script>
import datatableListaAppuntamenti from "../datatable/datatableListaAppuntamenti.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import pdfViewer from "../pdfViewer/pdfViewer.vue";
import strutture from "../../classes/strutture";
import UtilityMixin from "../../utilities/utilityMixin";

export default {
  mixins: [UtilityMixin],
  components: {
    datatableListaAppuntamenti,
    Loading,
    pdfViewer,
  },
  data() {
    return {
      pathResourceListaPagamenti: "/api/v2.0/appecupt/catomaior/listaPagamenti",
      pathResorceContatti: "/api/v2.0/appecupt/auth/me",
      pathResourceInfoUtente: "/api/v2.0/appecupt/auth/prelevaAnagraficaUtente",
      pathResourcePaga: "/api/v2.0/appecupt/aslengi/purchase",
      pathResorceScaricaAvviso: "/api/v2.0/appecupt/aslengi/stampaAvvisoPagamento",
      assistitoOptions: [{ value: null, text: "Seleziona Assistito", disabled: true }],
      statiPagamentoNonDisp: ["In elaborazione", "Pagamento non disponibile"],
      jsonData: { assistito: {} },
      jsonCedolino: {
        nre: "",
        assistito: {
          nome: "",
          cognome: "",
          dataNascita: "",
          codiceFiscale: "",
        },
        appuntamenti: [
          {
            desPrestazione: "",
            dataAppuntamento: "",
            idPrenotazione: "",
            booleanNota: "",
            nota: "",
            reparto: { desReparto: "", ubicazioneReparto: "" },
          },
        ],
      },
      title: "Avviso Pagamento",
      infoUtente: null,
      token: null,
      tokenLogin: null,
      items: [],
      item: [],
      isLoading: false,
      fullPage: true,
      pdf: null,
      linkPrenota: "",
      listaStrutture: strutture,
      messaggioPrePagamento: JSON.parse(localStorage.getItem("messaggiUtente")).messaggioPrePagamento,
    };
  },
  mounted: function () {
    let me = this;
    me.token = localStorage.getItem("tokenWSO2");
    me.tokenLogin = localStorage.getItem("loginData");
    me.loadData();
    me.loadInfoUtente();
  },
  watch: {
    "jsonData.codiceFiscale": function () {
      let me = this;
      if (me.jsonData.codiceFiscale) {
        me.jsonCedolino.assistito = me.jsonData;
        me.loadPendenze();
      }
    },
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResorceContatti;
      axios
        .get(link, {
          headers: {
            struttura: "100001",
            Authorization: `Bearer ${me.token}`,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            me.assistitoOptions.push({
              value: element,
              text: `${element.nome} ${element.cognome} - ${element.codiceFiscale}`,
            });
          });
        })
        .catch((error) => {
          error;
          UtilityMixin.methods.errorAuthWso2(this, error.response);
        });
    },
    loadInfoUtente() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceInfoUtente;

      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          me.jsonData = response.data.data;
          me.assistitoOptions.push({
            value: response.data.data,
            text: `${response.data.data.nome} ${response.data.data.cognome} - ${response.data.data.codiceFiscale}`,
          });
        })
        .catch((error) => {
          error;
          UtilityMixin.methods.errorAuthWso2(this, error.response);
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    loadPendenze() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaPagamenti;
      me.isLoading = true;
      let totRichieste = 0;
      me.listaStrutture.forEach((element) => {
        axios
          .post(
            link,
            { codiceFiscale: me.jsonData.codiceFiscale },
            {
              headers: {
                struttura: element.codice,
                "x-access-token": me.tokenLogin,
                Authorization: `Bearer ${me.token}`,
              },
              timeout: 500000,
            }
          )
          .then((response) => {
            if (Array.isArray(response.data.data) && response.data.data.length > 0) me.items = me.items.concat(response.data.data);
            me.items.forEach((element) => {
              me.item.push(element);
            });
            totRichieste += 1;
            me.isLoading = totRichieste < me.listaStrutture.length ? true : false;
          })
          .catch((error) => {
            me.isLoading = false;
            console.log(error);
            UtilityMixin.methods.errorAuthWso2(this, error);
          });
      });
    },
    onPaga(item) {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourcePaga;
      const codice = item.idTransazione;

      me.$bvModal
        .msgBoxConfirm(me.messaggioPrePagamento, {
          title: "Conferma Pagamento",
          footerClass: "p-2",
          centered: true,
        })
        .then((response) => {
          if (response) {
            me.isLoading = true;
            axios
              .post(
                link,
                { idTransazione: codice },
                {
                  headers: {
                    struttura: item.codiceStruttura,
                    "x-access-token": me.tokenLogin,
                    Authorization: `Bearer ${me.token}`,
                  },
                  timeout: 50000,
                }
              )
              .then((response) => {
                me.isLoading = false;
                if (response.data.stato === 200) {
                  me.isLoading = false;
                  location.href = response.data.data;
                } else {
                  me.$bvModal
                    .msgBoxOk(response.data.errore, {
                      title: "Errore",
                      size: "md",
                      okVariant: "outline-danger",
                      headerClass: "vv-msg-header-danger",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {
                      me.isLoading = false;
                    })
                    .catch((err) => {
                      console.log(err);
                      me.isLoading = false;
                    });
                }
              })
              .catch((error) => {
                console.log(error);
                me.isLoading = false;
              });
          }
          me.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          me.isLoading = false;
        });
    },
    onScaricaAvviso(item) {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResorceScaricaAvviso;
      me.isLoading = true;
      axios
        .post(
          link,
          {
            idTransazione: item.idTransazione,
          },
          {
            headers: {
              struttura: item.codiceStruttura,
              "x-access-token": me.tokenLogin,
              Authorization: `Bearer ${me.token}`,
            },
          }
        )
        .then((response) => {
          me.jsonData.pdf = response.data.data;
          me.isLoading = false;
        })
        .catch((error) => {
          me.isLoading = false;
          UtilityMixin.methods.errorAuthWso2(this, error.response);
          error;
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
