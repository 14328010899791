import BaseService from "./BaseService";

export default class Storico extends BaseService {

    static async getStorico(struttura, cf){
        return await this.perform({
            url:'/catomaior/storicopagamenti/'+ struttura,
            method: 'GET',
            params: {cf: cf}
        })
    }

    static async scaricaFattura(struttura, cf, body){
        return await this.perform({
            url: "/catomaior/recuperofattura/"+ struttura,
            method: "POST",
            params: {cf: cf},
            body: body
        })
    }
}
