<template>
  <div>
    <navbar2></navbar2>
    <div class="mt-15 container card_container">
      <b-container class="bv-example-row">
        <b-card bg-variant="light">
          <div class="mb-5 titolo">
            <h3 class="mb-5 primary">Recupera Password</h3>
          </div>
          <b-container class="bv-example-row pt-lg-6">
            <b-row class="text-center">
              <b-col sm class="mb-7">
                <strong><span class="text-center">Inserisci il codice PIN</span></strong>
                <div class="mt-5">
                  <PincodeInput v-model="pin" placeholder="" :length="6" />
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-container>
    </div>
    <b-modal ref="modalNuovaPassword" title="Inserisci nuova password" size="xl" ok-title="Conferma" cancel-title="Annulla" @ok="cambiaPassword" hide-header-close>
      <b-form-row class="mb-4">
        <strong>Nuova Password</strong>
        <b-form-input type="password" v-model="nuovaPassword" :state="validatePwd1" id="password1" :disableStrength="true" />
      </b-form-row>
      <b-form-row class="mb-4">
        <strong>Ripeti Nuova Password</strong>
        <b-form-input type="password" v-model="nuovaPassword2" :state="validatePwd2" id="password1" :disableStrength="true" />
      </b-form-row>
    </b-modal>
  </div>
</template>
<script>
import navbar2 from "../navbar/navbar2.vue";
import PincodeInput from "vue-pincode-input";
import RecuperoCredenziali from "@/services/RecuperoCredenziali";
export default {
  components: { PincodeInput, navbar2 },
  data() {
    return {
      tokenLogin: null,
      pin: "",
      email: "",
      nuovaPassword: "",
      nuovaPassword2: "",
      regexSpazio: /^\S*$/,
    };
  },
  mounted: function () {
    this.email = this.$route.query.email
  },
  computed: {
    validatePwd1(){
      var regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
      return this.nuovaPassword.length === 0 ? null : regexPassword.test(this.nuovaPassword) && this.regexSpazio.test(this.nuovaPassword);
    },
    validatePwd2(){
      return this.nuovaPassword2.length === 0 ? null : this.nuovaPassword2 === this.nuovaPassword;
    }
  },
  methods: {
    async cambiaPassword(){
      this.isLoading = true;
      const body= {
        email: this.email,
        pin: this.pin,
        password: this.nuovaPassword
      }
      const response = await RecuperoCredenziali.cambioPassword(body)
      if(!response.data.error){
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Congratulazioni",
              size: "md",
              okVariant: "outline-primary",
              headerClass: "vv-msg-header-primary",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {
              this.$router.push('/')
              this.isLoading = false;
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
      } else {
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        this.isLoading = false;
        this.nuovaPassword = ""
        this.nuovaPassword2 = ""
      }
    }
  },
  watch: {
    pin: async function (value) {
      if (value.length === 6) {
        const response = await RecuperoCredenziali.verificaPin(value, this.email)
        if(!response.data.error){
          this.$refs.modalNuovaPassword.show()
        } else {
          this.$bvModal.msgBoxOk(response.data.msg, {
            title: "Errore",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "vv-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        }
      }
    },
  },
};
</script>
