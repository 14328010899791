<template>
  <div class="mt-15 container card_container" v-if="jsonData.stepAlpi === 0">
    <b-container class="bv-example-row">
      <div class="mb-5">
        <h3 class="mt-5 mb-5 titolo"><i class="fas fa-laptop-medical"></i> Prestazioni Sanitarie Private</h3>
      </div>
      <!--<lista-strutture-pineta-check @setStruttura="setStruttura"></lista-strutture-pineta-check>-->
      <!--<h3 class="mt-5 mb-5" style="color: #11A7BE">Struttura di prenotazione: Catomaior</h3>-->
      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong class="text-primary">Seleziona Assistito</strong>
          </div>
            <b-form-select
                class="form-select"
                v-model="jsonData.assistito"
                :options="assistitoOptions"
                value-field="value"
                text-field="text"
                @change="getSelectedAssistito"
            ></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm class="mb-2 mt-5">
          <div class="mb-1">
            <strong class="text-primary">Prestazione</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="input1"
            placeholder="Inserisci visita"
            value=""
            style="text-transform: uppercase"
            v-model="jsonData.visita"
            v-on:keyup.enter="onCerca"
          />
        </b-col>
        <!--<b-col sm class="mb-2 mt-5">
          <div class="mb-1">
            <strong class="text-primary">Medico</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="input1"
            placeholder="Inserisci medico"
            value=""
            style="text-transform: uppercase"
            v-model="jsonData.medico"
            v-on:keyup.enter="onCerca"
          />
        </b-col>-->
      </b-row>

      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a>
          <b-button variant="outline-primary" class="mb-8" @click="onCerca" :disabled="disabilitaCerca"><i class="fas fa-search"></i> Cerca </b-button>
        </a>
      </div>
      <div v-if="listaPrestazioni.length > 0">
        <datatable-lista-prestazioni-privata :listaPrestazioni="listaPrestazioni" @getPrestazioneSelezionata="getPrestazioneSelezionata"></datatable-lista-prestazioni-privata>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button variant="outline-primary" :disabled="!prestazioneSelezionata" class="my-5" @click="onAvanti"><i class="fas fa-chevron-right"></i> Avanti </b-button>
        </div>
      </div>
      <div v-else-if="prestazioniNonTrovate === true">
        <b-row>
          <b-col sm class="mb-7 text-center">
            <strong>La ricerca non ha prodotto nessuna prestazione</strong>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
  </div>
  <disponibilita-privata
    v-else-if="jsonData.stepAlpi === 1"
    :assistito="jsonData.assistito"
    @setStep="setStep"
    :prestazioneSelezionata="prestazioneSelezionata"
    :prestazioneSelezionataPrimaDisp="prestazioneSelezionataPrimaDisp"
  ></disponibilita-privata>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import datatableListaPrestazioniPrivata from "../datatable/datatableListaPrestazioniPrivata.vue";
import DisponibilitaPrivata from "./disponibilitaPrivata.vue";
import UtilityMixin from "../../utilities/utilityMixin.js";
//import ListaStrutturePinetaCheck from "../elencoStrutture/listaStrutturePinetaCheck.vue";
import Utenti from "@/services/Utenti";
import Contatti from "@/services/Contatti";
import PrenotazioniPrivate from "@/services/PrenotazioniPrivate";

export default {
  mixins: [UtilityMixin],
  components: { Loading, datatableListaPrestazioniPrivata, DisponibilitaPrivata, /*ListaStrutturePinetaCheck*/ },
  data() {
    return {
      pathResourceListaPrestazioni: "/api/v2.0/appecupt/catomaior/private/prestazioniPrenotabili",
      pathResourceContatti: "/api/v2.0/appecupt/auth/this",
      pathResorceInfoUtente: "/api/v2.0/appecupt/auth/prelevaAnagraficaUtente",
      pathResourcePrimaDisponibilita: "/api/v2.0/appecupt/catomaior/private/ricercaDisponibilita",
      assistitoOptions: [],
      jsonData: {
        visita: "",
        provincia: null,
        stepAlpi: 0,
        assistito: null,
        medico: "",
      },
      isLoading: false,
      listaPrestazioni: [],
      zona: "",
      prestazioneSelezionata: null,
      prestazioneSelezionataPrimaDisp: [],
      strutturaSelezionata: null,
      struttura: ["100001"],
      prestazioniNonTrovate: false,
      disabilitaCerca: false,
    };
  },
  mounted: function () {
    this.token = localStorage.getItem("tokenWSO2");
    this.tokenLogin = localStorage.getItem("loginData");
    this.loadContatti();
  },
  methods: {
    async loadContatti() {
      const response = await Contatti.getDatiContatti()
      if(!response.data.error){
        for(let i=0; i<response.data.data.length; i++)
        this.assistitoOptions.push({
          disabled: false,
          value: response.data.data[i],
          text: `${response.data.data[i].nome} ${response.data.data[i].cognome} - ${response.data.data[i].codiceFiscale}`
        })
        await this.loadInfoUtente();
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal.msgBoxOk(response.data.msg, {
          title: "Errore " + response.data.error,
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
    .then(() => {})
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          });
      }
    },
    async loadInfoUtente() {
      const response = await Utenti.getDatiUtente()
      if(!response.data.error){
          this.assistitoOptions.unshift({
            disabled: false,
            value: response.data.data,
            text: `${response.data.data.nome} ${response.data.data.cognome} - ${response.data.data.codiceFiscale}`
          })
        this.jsonData.assistito = response.data.data
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal.msgBoxOk(response.data.msg, {
          title: "Errore " + response.data.error,
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
      }
    },
    async onCerca() {
      this.isLoading = true;
      if (this.jsonData.visita === "" || this.jsonData.visita === null) {
        this.isLoading = false;
        await this.$bvModal
            .msgBoxOk('Il campo "Prestazione" non può essere vuoto', {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
        return
      }
      for (const element of this.struttura) {
        const response = await PrenotazioniPrivate.ricercaPrestazione(element, this.jsonData.visita.toUpperCase(), this.jsonData.medico.toUpperCase())
        if(!response.data.error){
          this.isLoading = false;
          if (Array.isArray(response.data.data) && response.data.data.length > 0) {
            this.listaPrestazioni = response.data.data;
          } else if (response.data.data.length === 0) {
            this.listaPrestazioni = []
            this.prestazioniNonTrovate = true;
          }
        } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
                this.isLoading = false;
                localStorage.clear();
                window.location.assign('/')
                /*this.$router.replace("/#").catch((err) => {
                  err;
                });*/
              })
              .catch(() => {
                this.isLoading = false;
              });
        }
        else{
          this.isLoading = false;
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
        }
      }
    },
    async primaDisponibilita() {
      const body= {
        codprest: this.prestazioneSelezionata.codprest,
        desbprest: this.prestazioneSelezionata.desbprest,
        desbunitaop: this.prestazioneSelezionata.desbunitaop,
        codunitaop: this.prestazioneSelezionata.codunitaop,
        codreparto: this.prestazioneSelezionata.codreparto,
      }
      const response = await PrenotazioniPrivate.ricercaPrimaDisponibilita(this.prestazioneSelezionata.codstruttura, body)
      if(!response.data.error){
        this.isLoading = false;
        response.data.data.codstruttura = this.prestazioneSelezionata.codstruttura;
        response.data.data.importo = this.prestazioneSelezionata.importo;
        this.prestazioneSelezionataPrimaDisp = response.data.data;
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
      }
    },
    setStep(step) {
      this.jsonData.stepAlpi = step;
      if (this.jsonData.stepAlpi === 0) {
        this.jsonData.visita = "";
        this.listaPrestazioni = [];
        this.prestazioneSelezionata = null;
        this.prestazioneSelezionataPrimaDisp = [];
      } else if (this.jsonData.stepAlpi === 1) {
        this.strutturaSelezionata = null;
      }
    },
    getPrestazioneSelezionata(item) {
      this.prestazioneSelezionata = item;
    },
    getStrutturaSelezionata(item) {
      this.strutturaSelezionata = item;
    },
    getSelectedAssistito(assistito) {
      this.jsonData.assistito = assistito;
    },
    async onAvanti() {
      await this.primaDisponibilita();
      this.setStep(1);
    },
    setStruttura(item) {
      this.struttura = item;
    },
  },
  /*watch: {
    struttura: function (value) {
      if (value) {
        this.disabilitaCerca = false;
      }
    },
  },*/
};
</script>
