<template>
  <div>
    <div>
      <b-container class="bv-example-row">
        <div class="mt-15 container card_container">
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Codice fiscale</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="cf"
                placeholder="Codice Fiscale"
                value=""
                v-model="jsonData.codiceFiscale"
                style="text-transform: uppercase"
                :state="validationCodiceFiscale"
                maxlength="16"
                @keydown.space.prevent
              />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Tessera Sanitaria</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="cf"
                placeholder="Ultime 5 cifre codice tessera sanitaria"
                value=""
                v-model="jsonData.numeroTs"
                required
                :state="validationnumeroTs"
                maxlength="5"
                @keydown.space.prevent
              />
            </b-col>
          </b-row>
          <div v-if="codiceFiscaleInserito">
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong>Nome</strong></div>
                <b-form-input
                  type="text"
                  class="form-control form-control-solid"
                  name="nome"
                  placeholder="Nome"
                  value=""
                  v-model.trim="jsonData.nome"
                  disabled
                  style="text-transform: uppercase"
                />
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong>Cognome</strong>
                </div>
                <b-form-input
                  type="text"
                  class="form-control form-control-solid"
                  name="cognome"
                  placeholder="Cognome"
                  value=""
                  v-model.trim="jsonData.cognome"
                  disabled
                  style="text-transform: uppercase"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong>Data di nascita</strong></div>
                <b-form-input
                  type="text"
                  class="form-control form-control-solid"
                  name="datanascita"
                  placeholder="Data di nascita"
                  value=""
                  v-model="jsonData.dataNascita"
                  locale="it"
                  disabled
                />
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong>Luogo di nascita</strong>
                </div>
                <b-form-input
                  type="text"
                  class="form-control form-control-solid"
                  name="luogonascita"
                  value=""
                  v-model="luogoNascita.descrizione"
                  style="text-transform: uppercase"
                  disabled
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong>Sesso</strong>
                </div>
                <b-form-select class="form-select" v-model="jsonData.sesso" :options="sessoOptions" disabled> </b-form-select>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong>Stato civile</strong>
                </div>
                <b-form-select class="form-select" v-model="jsonData.statoCivile" :state="validateStatoCivile" :options="statoCivileOptions" :value="null"></b-form-select>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong>Provincia di residenza</strong>
                </div>
                <b-form-select
                  class="form-select"
                  v-model="jsonData.provinciaResidenza"
                  :options="provinciaResidenzaOptions"
                  :value="null"
                  value-field="value"
                  text-field="text"
                  :state="validateProvinciaResidenza"
                  @change="getSelectedProvinciaResidenza"
                  style="text-transform: uppercase"
                ></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong> Comune di residenza</strong>
                </div>
                <b-form-select
                  class="form-select"
                  v-model="jsonData.comuneResidenza"
                  :value="null"
                  value-field="value"
                  :options="comuniResidenzaOptions"
                  :state="validateComuneResidenza"
                  text-field="text"
                  style="text-transform: uppercase"
                  :disabled="disableComuneResidenza"
                ></b-form-select>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong>Indirizzo di residenza</strong>
                </div>
                <b-form-input v-model="jsonData.indirizzoResidenza" :value="null" :state="validateIndirizzoResidenza" value-field="value" text-field="" style="text-transform: uppercase" type="text"></b-form-input>
              </b-col>
            </b-row>
            <b-row v-if="nazionalitaStraniera">
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong>Nazionalità</strong>
                </div>
                <b-form-select
                  class="form-select"
                  v-model="jsonData.cittadinanza"
                  :options="cittadinanzaOptions"
                  :value="null"
                  value-field="value"
                  text-field="text"
                ></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong>Cellulare</strong>
                </div>
                <b-form-input
                  type="text"
                  class="form-control form-control-solid"
                  name="provincianascita"
                  placeholder="Cellulare"
                  value=""
                  required
                  maxlength="10"
                  :state="validationCellulare"
                  v-model="jsonData.numeroCellulare"
                />
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong>Telefono fisso</strong>
                </div>
                <b-form-input
                  type="text"
                  class="form-control form-control-solid"
                  name="provincianascita"
                  placeholder="Telefono fisso"
                  value=""
                  required
                  maxlength="10"
                  :state="validationFisso"
                  v-model="jsonData.numeroFisso"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong>Indirizzo Email</strong>
                </div>
                <b-form-input
                    type="text"
                    class="form-control form-control-solid"
                    name="provincianascita"
                    placeholder="Indirizzo Email"
                    value=""
                    v-model="jsonData.email"
                />
              </b-col>
            </b-row>
          </div>
          <div class="footer d-flex justify-content-end">
            <b-button variant="light" class="text-primary" @click="onIndietro"> <i class="fas fa-angle-double-left"></i> Indietro </b-button>

            <b-button variant="primary" class="ml-2" @click="onAggiungi"> <i class="fas fa-check"></i> Aggiungi </b-button>
          </div>
        </div>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Utils from "@/services/Utils";
import Contatti from "@/services/Contatti";
export default {
  components: { Loading },
  data() {
    return {
      jsonData: {
        codiceFiscale: "",
        numeroTs: "",
        numeroCellulare: "",
        numeroFisso: "",
        email:"",
        codiceNazionalita: "",
        controlloStep1: true,
        controlloStep2: true,
        controlloStep3: true,
        provinciaResidenza: null,
        comuneResidenza: null,
      },
      luogoNascita:{
        descrizione: 'hdksjahdsadas'
      },
      sessoOptions: [
        { value: "M", text: "MASCHIO" },
        { value: "F", text: "FEMMINA" },
      ],
      provinciaResidenzaOptions: [{ value: null, text: "Seleziona la Provincia", disabled: true }],
      cittadinanzaOptions: [{ value: null, text: "Seleziona la cittadinanza", disabled: true }],
      comuniResidenzaOptions: [
        { value: null, text: "Seleziona il Comune", disabled: true },
        {
          value: {
            cap: this.capResidenza,
            codice: this.codIstatComuneResidenza,
            nome: this.comuneResidenza,
            provinciaSigla: this.provinciaResidenzaSigla,
          },
          text: this.comuneResidenza,
        },
      ],
      statoCivileOptions: [{ value: null, text: "Seleziona Stato Civile", disabled: true }],

      token: null,
      nazionalitaStraniera: false,
      isLoading: false,
      codiceFiscaleInserito: false,
      disableComuneResidenza: true,
    };
  },
  computed: {
    validationCodiceFiscale() {
      var regexCodiceFiscale =
        /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
      return this.jsonData.codiceFiscale.length === 0 ? null : regexCodiceFiscale.test(this.jsonData.codiceFiscale) ? true : false;
    },
    validationnumeroTs() {
      var regexnumeroTs = /^([0-9])*$/;
      return this.jsonData.numeroTs.length === 0 ? null : regexnumeroTs.test(this.jsonData.numeroTs) && this.jsonData.numeroTs.length === 5 ? true : false;
    },
    validationCellulare() {
      if(!this.jsonData.numeroCellulare && !this.jsonData.numeroFisso)
        return false
      const regexCellulare = /^3\d{8,9}$/;
      return !this.jsonData.numeroCellulare? true : regexCellulare.test(this.jsonData.numeroCellulare) ? true : false;
    },
    validationFisso() {
      if(!this.jsonData.numeroCellulare && !this.jsonData.numeroFisso)
        return false
      let regexFisso = /^0\d+$/;
      return !this.jsonData.numeroFisso? true : regexFisso.test(this.jsonData.numeroFisso) ? true : false;
    },
    validateMail(){
      if(this.jsonData.email){
        let regexMail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return regexMail.test(this.jsonData.email)
      }
      return true
    },
    validateComuneResidenza() {
      return !this.jsonData.comuneResidenza ? false : true;
    },
    validateIndirizzoResidenza() {
      return !this.jsonData.indirizzoResidenza ? false : true;
    },
    validateStatoCivile() {
      return !this.jsonData.statoCivile ? false : true;
    },
    validateProvinciaResidenza() {
      return !this.jsonData.provinciaResidenza ? false : true;
    }
  },
  mounted: function () {
    this.token = localStorage.getItem("tokenWSO2");
    this.tokenLogin = localStorage.getItem("loginData");
    this.loadListaProvince();
  },
  watch: {
    "jsonData.codiceFiscale": function (value) {
      this.jsonData.codiceFiscale = value.toUpperCase();
    },
    "jsonData.numeroTs": function () {
      if (this.jsonData.numeroTs.length === 5 && this.validationCodiceFiscale) this.loadInfoContatto();
    },
  },
  methods: {
    async loadInfoContatto() {
      if (!this.validationCodiceFiscale || !this.validationnumeroTs) return;
      this.isLoading = true;
      const body = {
        codiceFiscale: this.jsonData.codiceFiscale,
        codiceTS:this.jsonData.numeroTs
      }
      const responseControllo = await Contatti.controlloUtenza(body)
      if(!responseControllo.data.error){
        const response = await Utils.codiceFiscaleInverso(this.jsonData.codiceFiscale, this.jsonData.numeroTs)
        if(!response.data.error){
          if (this.jsonData.codiceFiscale[11] === 'Z')
            await this.loadNazioneNascita();
          else
            await this.loadProvinciaNascita();
          this.jsonData = response.data.data;
          this.codiceFiscaleInserito = true;
          this.controlloNazionalita();
          this.isLoading = false;
        }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
                this.isLoading = false;
                localStorage.clear();
                window.location.assign('/')
                /*this.$router.replace("/#").catch((err) => {
                  err;
                });*/
              })
              .catch(() => {
                this.isLoading = false;
              });
        } else {
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore " + response.data.code,
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {})
              .catch((err) => {
                this.isLoading = false;
                console.log(err);
              });
          this.isLoading = false
        }
      } else {
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(responseControllo.data.msg, {
              title: "Errore " + responseControllo.data.code,
              size: "lg",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
            })
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
      }

    },
    async loadListaProvince() {
      const response = await Utils.getProvince()
      console.log(response)
      if(!response.data.error){
        for (let i=0; i<response.data.data.length; i++){
          this.provinciaResidenzaOptions.push({
            disabled: false,
            value: response.data.data[i],
            text: response.data.data[i].label.toUpperCase()
          })
        }
        await this.loadStatoCivile()
      }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        console.log(response.data.msg)
      }
    },
    async loadStatoCivile() {
      const response = await Utils.getStatoCivile()
      if(!response.data.error){
        for(let i=0; i<response.data.data.length; i++){
          this.statoCivileOptions.push({
            disabled: false,
            value: response.data.data[i],
            text: response.data.data[i].descrizione
          })
        }
      }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        console.log(response.data.msg)
      }
    },
    async getSelectedProvinciaResidenza() {
      this.disableComuneResidenza = false
      this.comuniResidenzaOptions = [];
      const response = await Utils.getComuni(this.jsonData.provinciaResidenza.value, null)
      if(!response.data.error){
        for(let i=0; i< response.data.data.length; i++){
          this.comuniResidenzaOptions.push({
            disabled: false,
            value: response.data.data[i],
            text: response.data.data[i].descrizione
          })
        }
      }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        console.log(response.data.msg)
      }
    },
    async controlloNazionalita() {
      const response = await Utils.getNazioni()
      if(!response.data.error){
        for(let i=0; i<response.data.data.length; i++){
          this.cittadinanzaOptions.push({
            disabled: false,
            value: response.data.data[i],
            text: response.data.data[i].descrizione.toUpperCase()
          })
        }
      }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        console.log(response.data.msg)
      }
    },
    async loadProvinciaNascita() {
      const response = await Utils.getComuni(null, this.jsonData.codiceFiscale.substring(11,15))
      if(!response.data.error){
        this.luogoNascita = response.data.data[0];
      }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.$bvToast.toast(response.data.msg, {
          title: response.data.code,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          noCloseButton: true
        })
      }
    },
    async loadNazioneNascita() {
      const response = await Utils.getNazioneByCodCatastale(this.jsonData.codiceFiscale.substring(11,15));
      if (!response.data.error) {
        this.luogoNascita = response.data.data
      }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      }
      else {
        this.$bvToast.toast(response.data.msg, {
          title: response.data.code,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          noCloseButton: true
        })
      }
    },
    async onAggiungi() {
      if(this.validationCellulare && this.validationFisso && this.validateMail && this.validateComuneResidenza && this.validateIndirizzoResidenza && this.validateStatoCivile && this.validateProvinciaResidenza){
        const datiContatto = {
          nome: this.jsonData.nome,
          cognome: this.jsonData.cognome,
          codiceFiscale: this.jsonData.codiceFiscale,
          tesseraSanitaria: {
            codiceTS: this.jsonData.numeroTs,
            scadenza: this.jsonData.scadenzaTs
          },
          datiNascita:{
            dataNascita: this.jsonData.dataNascita,
            luogoNascita: this.luogoNascita.descrizione,
            istatLuogoNascita: this.luogoNascita.codiceIstat,
          },
          datiResidenza:{
            istatComuneResidenza: this.jsonData.comuneResidenza.codiceIstat,
            codIstatProvinciaResidenza: this.jsonData.provinciaResidenza.value,
            indirizzoResidenza: this.jsonData.indirizzoResidenza,
          },
          sesso: this.jsonData.sesso,
          telefono: this.jsonData.numeroCellulare,
          telefonoFisso: this.jsonData.numeroFisso,
          email: this.jsonData.email,
          nazionalita:{
            codiceNazionalita: this.jsonData.codiceNazionalita,
            desNazionalita: this.jsonData.desNazionalita
          },
          codStatoCivile: this.jsonData.statoCivile.id,
          statoCivile: this.jsonData.statoCivile.descrizione
        };
        this.isLoading = true;
        const response = await Contatti.aggiungiContatto(datiContatto)
        if(!response.data.error){
          this.isLoading = false;
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Congratulazioni",
                size: "md",
                okVariant: "outline-primary",
                headerClass: "vv-msg-header-primary",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {
                this.$emit("onIndietro");
                this.isLoading = false;
              })
              .catch((err) => {
                this.isLoading = false;
                console.log(err);
              });
        }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
                this.isLoading = false;
                localStorage.clear();
                window.location.assign('/')
                /*this.$router.replace("/#").catch((err) => {
                  err;
                });*/
              })
              .catch(() => {
                this.isLoading = false;
              });
        } else {
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                this.isLoading = false;
                console.log(err);
              });
          this.isLoading = false;
        }
      }
    },
    onIndietro() {
      this.$emit("onIndietro");
    },
  },
};
</script>
