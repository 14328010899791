<template>
  <div>
    <div class="mt-15 container card_container">
      <anagrafica></anagrafica>
      <div class="separator border-4 my-3"></div>

      <a href="#/primadisponibilita" >
       <b-button variant="outline-primary" class="my-5">
         <i class="fas fa-calendar-plus"></i> Ritorna alle prime
          disponibilità</b-button></a>


      <h3 class="titolo">
        <i class="fas fa-clipboard-list"></i> Lista altre proposte
      </h3>

      <b-alert show variant="danger" class="my-5">
        <i class="fas fa-exclamation-triangle text-danger"></i> Attenzione
        Selezionando una delle seguenti disponibilità, rifiuterai la prima
        disponibilità.</b-alert>
      <datatable-disponibilita></datatable-disponibilita>
    </div>
  </div>
</template>

<script>
import anagrafica from "../anagrafica/anagrafica.vue";
import DatatableDisponibilita from "../datatable/datatableDisponibilita.vue";
export default {
  components: {
    anagrafica,
    DatatableDisponibilita,
  },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style>
.right {
  padding-left: 200px;
}

a {
  text-decoration: none;
  color: #01803c;
}
a:hover {
  text-decoration: none;
  color: #fff;
}
</style>
