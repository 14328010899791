import BaseService from "./BaseService";

export default class Pagamento extends BaseService {

    static async inizzializzaPagamentoPrivata(params){
        return await this.perform({
            url:'/catomaior/privata/pagamento/init',
            method: 'POST',
            body: params
        })
    }

    static async inizzializzaPagamentoNre(params){
        return await this.perform({
            url:'/catomaior/nre/pagamento/init',
            method: 'POST',
            body: params
        })
    }
}
