<template>
  <div>
    <b-modal
      id="modal-5-nre"
      class="modal-content"
      title="Cambia data prenotazione"
      @ok="onRicercaDisponibilita"
      slot="modal-header"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      cancel-title="Chiudi"
      ok-title="Cambia Data"
    >
      <b-container class="bv-example-row">
        <b-row>
          <b-col>
            <b-calendar block v-model="dataSelezionata" :min="min" locale="it"></b-calendar>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      value: "",
      min: new Date(),
      dataSelezionata: null,
    };
  },
  methods: {
    onRicercaDisponibilita() {
      let me = this;
      me.dataricerca = moment(me.dataSelezionata).format("DD/MM/YYYY");
      me.$emit("getData", me.dataricerca);
    },
  },
};
</script>
