<template>
  <div>
    <b-container class="mt-15 container card_container">
      <h3 class="mb-5 titolo"><i class="fas fa-user"></i> Profilo Utente</h3>
      <b-row>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Nome</strong>
          <br />
          <span>{{ assistito.nome }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Cognome</strong>
          <br />
          <span>{{ assistito.cognome }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Codice Fiscale</strong>
          <br />
          <span>{{ assistito.codiceFiscale }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="6" xs="12" sm="12" md="12" lg="6">
          <strong>Luogo di Nascita</strong>
          <br />
          <span>{{ assistito.datiNascita.luogoNascita }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="6">
          <strong>Data di Nascita</strong>
          <br />
          <span>{{ assistito.datiNascita.dataNascita }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Sesso</strong>
          <br />
          <span>{{ assistito.sesso }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Stato Civile</strong>
          <br />
          <span>{{ assistito.statoCivile }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Provincia di Residenza</strong>
          <br />
          <span> {{ assistito.datiResidenza.provinciaResidenza }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="6" xs="12" sm="12" md="12" lg="6">
          <strong>Comune di Residenza</strong>
          <br />
          <span>{{ assistito.datiResidenza.comuneResidenza }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="6">
          <strong>Indirizzo di Residenza</strong>
          <br />
          <span>{{ assistito.datiResidenza.indirizzoResidenza }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="4" xs="12" sm="12" md="12" lg="4">
          <strong>Cellulare</strong>
          <br />
          <span>{{ assistito.telefono }}</span>
        </b-col>
        <b-col cols="4" xs="12" sm="12" md="12" lg="4">
          <strong>Telefono Fisso</strong>
          <br />
          <span>{{ assistito.telefonoFisso }}</span>
        </b-col>
        <b-col cols="4" xs="12" sm="12" md="12" lg="4">
          <strong>Email</strong>
          <br />
          <span>{{ assistito.email }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <div class="footer d-flex justify-content-end">
          <b-button variant="outline-warning" class="ml-2 text-primary-w" v-b-modal.modalCambioPassword> <i class="fas fa-key"></i> Cambio Password</b-button>
          <b-button variant="outline-primary" class="ml-2 text-primary-w" @click="onModifica"> <i class="fas fa-pen"></i> Modifica </b-button>
          <b-button variant="danger" class="ml-2 text-primary-w" v-b-modal.modalEliminaAccount> <i class="fas fa-trash"></i> Elimina </b-button>
        </div>
      </b-row>
    </b-container>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
    <modal-elimina-account @onElimina="onElimina"></modal-elimina-account>
    <modal-cambio-password @update="updatePassword"></modal-cambio-password>
  </div>
</template>
<script>
import UtilityMixin from "../../utilities/utilityMixin.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import modalEliminaAccount from "../modal/modalEliminaAccount.vue";
import Utenti from "@/services/Utenti";
import RecuperoCredenziali from "@/services/RecuperoCredenziali";
import {DateTime} from "luxon";
import ModalCambioPassword from "@/components/modal/modalCambioPassword";
export default {
  components: {ModalCambioPassword, Loading, modalEliminaAccount },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResorceInfoUtente: "/api/v2.0/appecupt/auth/prelevaAnagraficaUtente",
      pathResourceEliminaUtente: "/api/v2.0/appecupt/auth/eliminaaccount",
      assistito: {},
      tokenLogin: null,
      isLoading: false,
      password: "",
    };
  },
  computed: {
    numeroCellulareAssente() {
      return this.assistito.numeroCellulare === "" || this.assistito.numeroCellulare === null ? "NUMERO CELLULARE ASSENTE" : this.assistito.numeroCellulare;
    },
    numeroFissoAssente() {
      return this.assistito.numeroFisso === "" || this.assistito.numeroFisso === null ? "NUMERO TELEFONO FISSO ASSENTE" : this.assistito.numeroFisso;
    },
  },
  mounted: function () {
    this.tokenLogin = localStorage.getItem("loginData");
    this.token = localStorage.getItem("tokenWSO2");
    this.loadInfoUtente();
  },
  methods: {
    async loadInfoUtente() {
      const response = await Utenti.getDatiUtente()
      if(!response.data.error){
        this.assistito = response.data.data
        this.assistito.datiNascita.dataNascita = DateTime.fromISO(this.assistito.datiNascita.dataNascita).toFormat('dd/MM/yyyy')
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal.msgBoxOk(response.data.msg, {
          title: "Errore " + response.data.error,
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
      }
    },
    async updatePassword(pwd){
      const body = {
        vecchiaPwd: pwd.password,
        nuovaPwd: pwd.nuovaPassword
      }
      this.isLoading = true;
      const response = await RecuperoCredenziali.cambioPasswordAuth(body)
      if(!response.data.error){
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Esito Cambio Password",
              size: "md",
              okVariant: "primary",
              headerClass: "header-primary",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              this.$router.replace("/#/").catch((err) => {
                console.log(err);
              });
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore Cambio Password",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
      }
    },
    async onElimina(item) {
      this.$bvModal
          .msgBoxConfirm("Eliminando il profilo Utente eliminerai anche i contatti, Sei sicuro di voler continuare?", {
            title: "Conferma Eliminazione",
            footerClass: "p-2",
            centered: true,
            okTitle: "Conferma",
            cancelTitle: "Annulla",
          })
          .then(async (accept) => {
            if (!accept) {
              return
            }
            const response = await Utenti.eliminaUtente(this.assistito.codiceFiscale, item)
            if(!response.data.error){
              this.isLoading = false;
              this.$bvModal
                  .msgBoxOk(response.data.msg, {
                    title: "Esito Eliminazione",
                    size: "md",
                    okVariant: "primary",
                    headerClass: "header-primary",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                    okTitle: "Chiudi",
                  })
                  .then(() => {
                    this.isLoading = false;
                    this.$router.replace("/#/").catch((err) => {
                      console.log(err);
                    });
                  })
                  .catch(() => {
                    this.isLoading = false;
                  });
            } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
              this.$bvModal
                  .msgBoxOk(response.data.msg, {
                    title: "Errore",
                    size: "md",
                    okVariant: "outline-danger",
                    headerClass: "vv-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                    okTitle: "Chiudi",
                  })
                  .then(() => {
                    this.isLoading = false;
                    localStorage.clear();
                    window.location.assign('/')
                    /*this.$router.replace("/#").catch((err) => {
                      err;
                    });*/
                  })
                  .catch(() => {
                    this.isLoading = false;
                  });
            } else {
              this.isLoading = false;
              this.$bvModal
                  .msgBoxOk(response.data.msg, {
                    title: "Errore Eliminazione",
                    size: "md",
                    okVariant: "outline-danger",
                    headerClass: "vv-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                    okTitle: "Chiudi",
                  })
                  .then(() => {
                    this.isLoading = false;
                  })
                  .catch(() => {
                    this.isLoading = false;
                  });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    onModifica() {
      this.$emit("modificaUtente", this.assistito);
    },
  },
};
</script>
