import BaseService from "./BaseService";

export default class Utils extends BaseService {

    static async getComuni(codIstat, codCatastale){
        return await this.perform({
            url:'/util/comuni',
            method: 'get',
            header:{},
            params: {
                codIstat: codIstat,
                codCatastale: codCatastale
            }
        })
    }

    static async getProvince(){
        return await this.perform({
            url: '/util/province',
            method: 'get',
            header:{}
        })
    }

    static async codiceFiscaleInverso(codiceFiscale, codiceTeam){
        return await this.perform({
            url: '/cf/decodifica',
            method: 'get',
            header: {},
            params: {codiceFiscale: codiceFiscale, codiceTeam: codiceTeam}
        })
    }

    static async controlloDatiRegistrazione(email, password){
        return await this.perform({
            url:'/cittadini/registrazione/controlloDati/account',
            method: 'post',
            header: {},
            body: {email: email, password: password}
        })
    }

    static async getStatoCivile(){
        return await this.perform({
            url:'/statocivile',
            method: 'get',
            header: {}
        })
    }

    static async getNazioni(){
        return await this.perform({
            url: '/util/nazioni',
            method: 'get',
            header:{}
        })
    }

    static async getNazioneByCodCatastale(codCatastale) {
        return await this.perform({
            url: `/util/nazioni/${codCatastale}`,
            method: 'get',
            header: {}
        })
    }

    static async richiestaAssistenza(body){
        return await this.perform({
            url: '/assistenza/',
            method:'post',
            headers: {},
            body: body
        })
    }
}
