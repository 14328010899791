<template>
  <div class="mt-15 container card_container">
    <b-container class="bv-example-row">
      <b-card bg-variant="light">
        <h3 class="mb-5" style="color: #0a894e"><i class="fas fa-exclamation-triangle"></i> Pagamento Non Riuscito</h3>
        <b-container class="bv-example-row pt-lg-6">
          <b-row class="text-center">
            <b-col sm class="mb-7">
              <strong><span class="text-center">A causa di un errore il pagamento è stato respinto.</span></strong>
              <strong><span class="text-center"> Sarà reindirizzato alla home tra {{timer}} secondi.</span></strong>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tokenLogin: null,
      timer: 5
    };
  },
  mounted: function () {
    setInterval(() => {
      this.timer -= 1
      if(this.timer === 0){
        this.$router.push('/prenota');
      }
    }, 1000)
  },
  methods: {
  },
};
</script>
