import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "./router/router.js";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import IdleVue from "idle-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('font-awesome-icon', FontAwesomeIcon);
const eventsHub = new Vue();

Vue.config.productionTip = false

Vue.use(IdleVue,{
  eventEmitter: eventsHub,
  idleTime: 60000 * 20 //20 minuti
});

new Vue({
  router,
  render: h => h(App),
  onIdle() {
    if(this.$route.name !== "login") {
      localStorage.setItem("token", "");
      localStorage.setItem("isAuthenticated", "false");
      alert("Disconnesso per inattività. Effettua nuovamente l'accesso")
      this.$router.push('/');
    }
  }
}).$mount('#app')
