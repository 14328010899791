<template>
  <div>
    <div class="mt-15 container card_container">
      <modal-cambia-data-disp-nre @getData="getData"></modal-cambia-data-disp-nre>
      <!--<anagrafica :codiceImpegnativa="presaInCarico.nre" :priorita="presaInCarico.classePriorita" :assistito="assistito"></anagrafica>-->
      <div class="separator border-4 my-3"></div>
      <b-row>
        <b-col cols="6">
          <b-button variant="outline-primary" class="my-5" @click="onProssimaDisponibilita"><i class="fas fa-calendar-minus"></i> Prossima disponibilità</b-button>
          <b-button v-b-modal.modal-5-nre variant="outline-primary" class="my-5 ml-3"><i class="fas fa-calendar-plus"></i> Cambia data prenotazione</b-button>
        </b-col>
        <b-col cols="6">
          <p class="my-5 text-end">
            Stai prenotando per la struttura: <strong>{{ strutturaPineta(struttura) }}</strong>
          </p>
        </b-col>
      </b-row>

      <h3 class="mb-5 titolo"><i class="fas fa-clipboard-list"></i> disponibilità</h3>

      <datatable-disponibilita :esamiDisponibili="jsonData.prossimiEsamiDisponibili"></datatable-disponibilita>
      <b-container class="bv-example-row">
        <b-row>
          <b-col>
            <a href="#/prenota">
              <b-button variant="outline-secondary" class="w-100" @click="onAnnulla"><i class="fas fa-trash"></i> Annulla</b-button></a
            >
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col>
            <b-button variant="outline-primary" class="w-100" @click="onConfermaPrenotazione"> Conferma <i class="fas fa-clipboard-check"></i></b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
  </div>
</template>
<script>
//import anagrafica from "../anagrafica/anagrafica.vue";
import Loading from "vue-loading-overlay";
import DatatableDisponibilita from "../datatable/datatableDisponibilita.vue";
import modalCambiaDataDispNre from "../modal/modalCambiaDataDispNre.vue";
import UtilityMixin from "../../utilities/utilityMixin";
import PrenotazioneNre from "@/services/PrenotazioneNre";
import Spostamento from "@/services/Spostamento";
import moment from "moment";

export default {
  mixins: [UtilityMixin],
  props: {
    assistito: Object,
    esamiDisponibili: Array,
    prenotazione: Object

  },
  components: {
    //anagrafica,
    DatatableDisponibilita,
    modalCambiaDataDispNre,
    Loading,
  },
  data() {
    return {
      showModal: false,
      struttura: this.prenotazione.codiceStruttura,
      jsonData: {
        prossimiEsamiDisponibili: [],
      },
      token: null,
      tokenLogin: null,
      isLoading: false,
      cronologiaDisponibilita: null,
    };
  },
  mounted: async function () {
    if(this.prenotazione.isNre){
      this.jsonData.prossimiEsamiDisponibili = this.esamiDisponibili;
      this.jsonData.prossimiEsamiDisponibili[0].data = this.jsonData.prossimiEsamiDisponibili[0].dataAppuntamento
      this.jsonData.prossimiEsamiDisponibili[0].ora = this.jsonData.prossimiEsamiDisponibili[0].oraAppuntamento
      this.loadData();
      await this.primaDisponibilita()
    } else {
      this.jsonData.prossimiEsamiDisponibili = this.esamiDisponibili;
      this.jsonData.prossimiEsamiDisponibili[0].data = this.jsonData.prossimiEsamiDisponibili[0].dataAppuntamento
      this.jsonData.prossimiEsamiDisponibili[0].ora = this.jsonData.prossimiEsamiDisponibili[0].oraAppuntamento
      this.loadData();
      await this.primaDisponibilitaPrivata()
    }
  },
  methods: {
    async primaDisponibilita(){
      const body= {
        codPrestazione: this.esamiDisponibili[0].codPrestazione,
        reparto: {
          codUnitaOp: this.esamiDisponibili[0].reparto.codUnitaOp,
          codReparto: this.esamiDisponibili[0].reparto.codReparto
        }
      }
      this.isLoading = true;
      const response = await Spostamento.ricercaDisponibilitaNre(this.struttura, body)
      if(!response.data.error){
        this.isLoading = false;
        this.jsonData.prossimiEsamiDisponibili = response.data.data.esamiDisponibili
        this.jsonData.prossimiEsamiDisponibili[0].idTransazione = response.data.data.idTransazione

      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    async primaDisponibilitaPrivata(){
      const body= {
        codprest: this.esamiDisponibili[0].codPrestazione,
        codunitaop: this.esamiDisponibili[0].reparto.codUnitaOp,
        codreparto: this.esamiDisponibili[0].reparto.codReparto,
        dataOrario: this.esamiDisponibili[0].dataAppuntamento + " " + this.esamiDisponibili[0].oraAppuntamento
      }
      this.isLoading = true;
      const response = await Spostamento.ricercaDisponibilitaPrivata(this.struttura, body)
      if(!response.data.error){
        this.isLoading = false;
        //this.jsonData.prossimiEsamiDisponibili.push(response.data.data.proposta)
        this.jsonData.prossimiEsamiDisponibili[0].idTransazione = response.data.data.proposta.idTransazione

      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        console.log('ERRORE AUTH');
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        console.log('ERRORE')
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    async onConfermaPrenotazione() {
      this.$bvModal
          .msgBoxConfirm("E' sicuro di voler confermare?", {
            title: "Conferma Prenotazione",
            footerClass: "p-2",
            centered: true,
            okTitle: "Conferma",
            cancelTitle: "Annulla",
          })
          .then(async (item) => {
            if (item) {
              this.isLoading = true;
              if(this.prenotazione.isNre){
                const body = {
                  assistito: {
                    codiceFiscale: this.assistito.codiceFiscale
                  },
                  nre: this.prenotazione.nre,
                  codiceAssistito: this.prenotazione.codiceAssistito,
                  appuntamentoNuovo: {
                    desPrestazione: this.jsonData.prossimiEsamiDisponibili[0].desPrestazione,
                    idTransazione: this.jsonData.prossimiEsamiDisponibili[0].idTransazione,
                    data: this.jsonData.prossimiEsamiDisponibili[0].data,
                    ora: this.jsonData.prossimiEsamiDisponibili[0].ora,
                    reparto: {
                      desReparto: this.jsonData.prossimiEsamiDisponibili[0].reparto.desReparto,
                      ubicazioneReparto: this.jsonData.prossimiEsamiDisponibili[0].reparto.ubicazioneReparto
                    }
                  },
                  appuntamentoVecchio: {
                    codiceDisponibilita: this.esamiDisponibili[0].codiceDisponibilita,
                    codiceAgenda: this.esamiDisponibili[0].codiceAgenda,
                    codiceFascia: this.esamiDisponibili[0].codiceFascia,
                    codiceAppuntamento: this.esamiDisponibili[0].codiceAppuntamento,
                    dataAppuntamento: this.esamiDisponibili[0].dataAppuntamento,
                    oraAppuntamento: this.esamiDisponibili[0].oraAppuntamento,
                    posizioneInCoda: this.esamiDisponibili[0].posizioneInCoda,
                    tipoPrenotazione: this.esamiDisponibili[0].tipoPrenotazione,
                    reparto: {
                      codReparto: this.esamiDisponibili[0].reparto.codReparto,
                      codUnitaOp: this.esamiDisponibili[0].reparto.codUnitaOp
                    }
                  }
                }
                this.isLoading = true;
                const response = await Spostamento.confermaSpostamentoNre(this.struttura, body)
                if (!response.data.error) {
                  this.isLoading = false;
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Esito Prenotazione",
                        size: "md",
                        okVariant: "outline-success",
                        headerClass: "vv-msg-primary-primary",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                        okTitle: "Chiudi",
                      })
                      .then(() => {
                        location.reload()
                        this.$router.push('/listaappuntamenti')
                      })
                      .catch(() => {
                        this.$router.push('/listaappuntamenti')
                      });
                } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Errore",
                        size: "md",
                        okVariant: "outline-danger",
                        headerClass: "vv-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                        okTitle: "Chiudi",
                      })
                      .then(() => {
                        this.isLoading = false;
                        localStorage.clear();
                        window.location.assign('/')
                      })
                      .catch(() => {
                        this.isLoading = false;
                      });
                } else {
                  this.isLoading = false;
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Errore",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "vv-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                        okTitle: "Chiudi",
                      })
                      .then(() => {
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                }
              } else {
                const body = {
                  codiceFiscale: this.assistito.codiceFiscale,
                  codiceAssistito: this.prenotazione.codiceAssistito,
                  appuntamentoNuovo: {
                    idTransazione: this.jsonData.prossimiEsamiDisponibili[0].idTransazione,
                    codPrestazione: this.jsonData.prossimiEsamiDisponibili[0].codPrestazione,
                    desPrestazione: this.jsonData.prossimiEsamiDisponibili[0].desPrestazione,
                    data: this.jsonData.prossimiEsamiDisponibili[0].data,
                    ora: this.jsonData.prossimiEsamiDisponibili[0].ora,
                    nota: this.jsonData.prossimiEsamiDisponibili[0].nota,
                    nomeMedico: this.jsonData.prossimiEsamiDisponibili[0].nomeMedico,
                    reparto: {
                      codUnitaOp: this.jsonData.prossimiEsamiDisponibili[0].reparto.codUnitaOp,
                      desReparto: this.jsonData.prossimiEsamiDisponibili[0].reparto.desReparto,
                      desUnitaOp: this.jsonData.prossimiEsamiDisponibili[0].reparto.desUnitaOp,
                      codReparto: this.jsonData.prossimiEsamiDisponibili[0].reparto.codReparto,
                      ubicazioneReparto: this.jsonData.prossimiEsamiDisponibili[0].reparto.ubicazioneReparto,
                      ubicazioneUnitaOp: this.jsonData.prossimiEsamiDisponibili[0].reparto.ubicazioneUnitaOp,
                      latitudine: this.jsonData.prossimiEsamiDisponibili[0].reparto.latitudine,
                      longitudine: this.jsonData.prossimiEsamiDisponibili[0].reparto.longitudine,
                      importo: this.jsonData.prossimiEsamiDisponibili[0].importo,
                    }
                  },
                  appuntamentoVecchio: {
                    codiceDisponibilita: this.esamiDisponibili[0].codiceDisponibilita,
                    codiceAgenda: this.esamiDisponibili[0].codiceAgenda,
                    codiceFascia: this.esamiDisponibili[0].codiceFascia,
                    codiceAppuntamento: this.esamiDisponibili[0].codiceAppuntamento,
                    dataAppuntamento: this.esamiDisponibili[0].dataAppuntamento,
                    oraAppuntamento: this.esamiDisponibili[0].oraAppuntamento,
                    posizioneInCoda: this.esamiDisponibili[0].posizioneInCoda,
                    tipoPrenotazione: this.esamiDisponibili[0].tipoPrenotazione,
                    reparto: {
                      codReparto: this.esamiDisponibili[0].reparto.codReparto,
                      codUnitaOp: this.esamiDisponibili[0].reparto.codUnitaOp
                    }
                  }
                }
                this.isLoading = true;
                const response = await Spostamento.confermaSpostamentoPrivata(this.struttura, body)
                if(!response.data.error){
                  this.isLoading = false
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Esito Prenotazione",
                        size: "md",
                        okVariant: "outline-primary",
                        okTitle: "Avanti",
                        headerClass: "vv-msg-header-primary",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                      })
                      .then((value) => {
                        if (value === true) {
                          location.reload()
                        }
                      })
                      .catch(() => {
                      });
                } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Errore",
                        size: "md",
                        okVariant: "outline-danger",
                        headerClass: "vv-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                        okTitle: "Chiudi",
                      })
                      .then(() => {
                        this.isLoading = false;
                        localStorage.clear();
                        window.location.assign('/')
                        /*this.$router.replace("/#").catch((err) => {
                          err;
                        });*/
                      })
                      .catch(() => {
                        this.isLoading = false;
                      });
                } else {
                  this.isLoading = false
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Errore",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "vv-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                      })
                      .then(() => {
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                }
              }
            }
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          });
    },
    loadData() {
      this.token = localStorage.getItem("tokenWSO2");
      this.tokenLogin = localStorage.getItem("loginData");
    },
    async onAnnulla() {
      this.isLoading = true;
      const response = await PrenotazioneNre.annullaPrenotazione(this.struttura, this.assistito.codiceFiscale)
      if(!response.data.error){
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Eliminazione",
              size: "sm",
              okVariant: "outline-primary",
              headerClass: "vv-msg-header-primary",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.$emit("setStep", 0);
            })
            .catch(() => {});
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
            })
            .catch(() => {});
      }
    },
    async onProssimaDisponibilita() {
      this.isLoading = true;
      if(this.prenotazione.isNre){
        const body = {
          codPrestazione: this.esamiDisponibili[0].codPrestazione,
          reparto: {
            codUnitaOp: this.esamiDisponibili[0].reparto.codUnitaOp,
            codReparto: this.esamiDisponibili[0].reparto.codReparto
          },
          data: this.esamiDisponibili[0].dataAppuntamento,
          ora: this.esamiDisponibili[0].oraAppuntamento
        }
        const response = await Spostamento.prossimaDisponibilitaNre(this.struttura, body)
        if(!response.data.error){
          this.jsonData.prossimiEsamiDisponibili = response.data.data.esamiDisponibili
          this.jsonData.prossimiEsamiDisponibili[0].idTransazione = response.data.data.idTransazione
          this.isLoading = false;
        } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
                this.isLoading = false;
                localStorage.clear();
                window.location.assign('/')
                /*this.$router.replace("/#").catch((err) => {
                  err;
                });*/
              })
              .catch(() => {
                this.isLoading = false;
              });
        } else {
          this.isLoading = false;
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {})
              .catch((err) => {
                this.isLoading = false;
                console.log(err);
              });
      }
      }
      else {
        const time = moment(this.esamiDisponibili[0].ora, "HH:mm").toDate();
        let minutiPrest = moment(time).add(1, "minutes");
        let oraPrest = moment(minutiPrest).format("HH:mm");
        const body= {
          codprest: this.esamiDisponibili[0].codPrestazione,
          codunitaop: this.esamiDisponibili[0].reparto.codUnitaOp,
          codreparto: this.esamiDisponibili[0].reparto.codReparto,
          dataOrario: this.esamiDisponibili[0].dataAppuntamento + " " + oraPrest
        }
        const response = await Spostamento.ricercaDisponibilitaPrivata(this.struttura, body)
        if(!response.data.error){
          this.isLoading = false;
          this.jsonData.prossimiEsamiDisponibili= []
          this.jsonData.prossimiEsamiDisponibili.push(response.data.data.proposta)
          this.jsonData.prossimiEsamiDisponibili[0].idTransazione = response.data.data.proposta.idTransazione

        } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
          console.log('ERRORE AUTH');
          this.isLoading = false;
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
                this.isLoading = false;
                localStorage.clear();
                window.location.assign('/')
                /*this.$router.replace("/#").catch((err) => {
                  err;
                });*/
              })
              .catch(() => {
                this.isLoading = false;
              });
        } else {
          this.isLoading = false;
          console.log('ERRORE')
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
              })
              .catch((err) => {
                console.log(err);
              });
        }
      }
    },
    async getData(data) {
      this.isLoading = true;
      if(this.prenotazione.isNre){
        const body = {
          codPrestazione: this.esamiDisponibili[0].codPrestazione,
          reparto: {
            codUnitaOp: this.esamiDisponibili[0].reparto.codUnitaOp,
            codReparto: this.esamiDisponibili[0].reparto.codReparto
          },
          data: data,
          ora: '08:00'
        }
        const response = await Spostamento.prossimaDisponibilitaDataNre(this.struttura, body)
        if(!response.data.error){
          this.jsonData.prossimiEsamiDisponibili = response.data.data.esamiDisponibili
          this.jsonData.prossimiEsamiDisponibili[0].idTransazione = response.data.data.idTransazione

          this.isLoading = false;
        } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
                this.isLoading = false;
                localStorage.clear();
                window.location.assign('/')
                /*this.$router.replace("/#").catch((err) => {
                  err;
                });*/
              })
              .catch(() => {
                this.isLoading = false;
              });
        } else {
          this.isLoading = false;
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {})
              .catch((err) => {
                this.isLoading = false;
                console.log(err);
              });
        }
      } else {
        const body = {
          codprest: this.esamiDisponibili[0].codPrestazione,
          codunitaop: this.esamiDisponibili[0].reparto.codUnitaOp,
          codreparto: this.esamiDisponibili[0].reparto.codReparto,
          dataOrario: data + " 07:00"
        }
        const response = await Spostamento.ricercaDisponibilitaPrivata(this.struttura, body)
        if (!response.data.error) {
          this.isLoading = false;
          this.jsonData.prossimiEsamiDisponibili= []
          this.jsonData.prossimiEsamiDisponibili.push(response.data.data.proposta)
          this.jsonData.prossimiEsamiDisponibili[0].idTransazione = response.data.data.proposta.idTransazione
        } else if (response.data.code === 'ERR_CHECK_AUTH_2') {
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
                this.isLoading = false;
                localStorage.clear();
                window.location.assign('/')
                /*this.$router.replace("/#").catch((err) => {
                  err;
                });*/
              })
              .catch(() => {
                this.isLoading = false;
              });
        } else {
          this.isLoading = false;
          this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
        }
      }
    },
  },
};
</script>
<style scoped>
.center {
  margin: auto;
  width: 60%;
  padding: 10px;
}
</style>
