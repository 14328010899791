<template>
  <div>
    <div class="my-5 container card_container" v-if="assistito.codiceFiscale != null">
      <b-card bg-variant="light">
        <b-container class="bv-example-row pt-lg-6">
          <b-row>
            <b-col sm class="mb-7">
              <span class="text-primary">Codice fiscale</span><br />
              <span>{{ assistito.codiceFiscale }}</span>
            </b-col>
            <b-col sm class="mb-7">
              <span class="text-primary">Assistito</span><br />
              <span>{{ assistito.nome }} {{ assistito.cognome }}</span>
            </b-col>
            <b-col sm class="mb-7">
              <span class="text-primary">Data di nascita</span><br />
              <span>{{ dataNascita }}</span>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </div>
    <div class="my-5 container card_container">
      <modal-cambia-data-disp :data="prestazioneSelezionataPrimaDisp" @getData="getData"></modal-cambia-data-disp>
      <b-row>
        <b-col cols="6">
          <!--<b-button variant="outline-primary" class="mb-5" @click="onProssimaDisponibilita"><i class="fas fa-calendar-minus"></i> Prossima disponibilità</b-button>-->
          <b-button v-b-modal.modal-5 variant="outline-primary" class="mb-5 ml-5" ><i class="fas fa-calendar-plus"></i> Cambia data disponibilità</b-button>
        </b-col>
      </b-row>
      <h3 class="mb-5 titolo"><i class="fas fa-clipboard-list"></i> disponibilità</h3>
      <b-row v-if="prestazionePrimaDisponibilita.length > 0">
        <b-col sm class="mb-7">
          <span class="text-primary">Prestazione</span><br />
          <span>{{ prestazionePrimaDisponibilita[0].proposta.desPrestazione }}</span>
        </b-col>
        <b-col sm class="mb-7">
          <span class="text-primary">Ambulatorio</span><br />
          <span>{{ prestazionePrimaDisponibilita[0].proposta.reparto.desReparto }}</span>
        </b-col>
        <b-col sm class="mb-7">
          <span class="text-primary">Giorno</span><br />
          <span>{{ prestazionePrimaDisponibilita[0].proposta.data }}</span>
        </b-col>
        <b-col sm class="mb-7">
          <span class="text-primary">Importo</span><br />
          <span>{{ prestazioneSelezionata.importo}} €</span>
        </b-col>
      </b-row>
      <span class="text-primary" style="font-size: large">Seleziona Orario</span><br />
      <b-form-checkbox v-for="(proposta,index) in prestazionePrimaDisponibilita"
                       :key="index"
                       button
                       button-variant="outline-primary"
                       v-model="proposta.proposta.checked"
                       size="lg"
                       class="checkbox-disponibilita"
                       @change="deselect(index)">
        {{proposta.proposta.ora}}</b-form-checkbox>
      <!--<b-form-group label="Seleziona Orario" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
            size="lg"
            button-variant="outline-secondary"
            v-model="disponibilitaSelezionata"
            :options="prestazionePrimaDisponibilita"
            text-field="proposta.ora"
            value-field="proposta"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
            @input="getDisponibilitaSelezionata"
        ></b-form-radio-group>
      </b-form-group>-->
      <!--<datatable-disponibilita-privata
          :prestazioneSelezionataPrimaDisp="prestazioneSelezionataPrimaDisp"
          :prestazionePrimaDisponibilita="prestazionePrimaDisponibilita"
          :importoPrestazione="prestazioneSelezionata.importo"
          @getDisponibilitaSelezionata="getDisponibilitaSelezionata"
      ></datatable-disponibilita-privata>-->

      <b-container class="bv-example-row mt-10">
        <b-row>
          <b-col>
            <b-button class="mb-5 btn-secondary" @click="onIndietro"><i class="fas fa-angle-double-left"></i> Indietro</b-button>
          </b-col>
          <b-col>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <b-button variant="outline-primary" :disabled="disponibilitaSelezionata == null" class="mb-5" @click="onConfermaPrenotazione"><i class="fas fa-clipboard-check"></i> Conferma</b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
//import DatatableDisponibilitaPrivata from "../datatable/datatableDisponibilitaPrivata.vue";
import UtilityMixin from "../../utilities/utilityMixin";
import ModalCambiaDataDisp from "../modal/modalCambiaDataDisp.vue";
//import moment from "moment";
import PrenotazioniPrivate from "@/services/PrenotazioniPrivate";
import {DateTime} from "luxon";
//import Pagamento from "@/services/Pagamento";

export default {
  mixins: [UtilityMixin],
  props: {
    assistito: Object,
    presaInCarico: Object,
    //esamiDisponibili: Array,
    sedeSelezionata: String,
    prestazioneSelezionataPrimaDisp: Array,
    //importoPrestazione: Number,
    prestazioneSelezionata: Object,
  },
  components: {
    //DatatableDisponibilitaPrivata,
    Loading,
    ModalCambiaDataDisp,
  },
  data() {
    return {
      showModal: false,
      dataNascita: '',
      messaggioPrePrenotazionePrivata: JSON.parse(localStorage.getItem("messaggiUtente")).messaggioPrePrenotazionePrivata,
      pathResourceAnnulla: "/api/v2.0/appecupt/aslengi/annullaprenotazionesospesa",
      pathResourceProssimaDisponibilita: "/api/v2.0/appecupt/catomaior/private/ricercaDisponibilita",
      pathResourceConferma: "/api/v2.0/appecupt/catomaior/private/confermaPrenotazione",
      jsonData: {
        //prossimiEsamiDisponibili: [],
      },
      token: null,
      tokenLogin: null,
      isLoading: false,
      prestazionePrimaDisponibilita: this.prestazioneSelezionataPrimaDisp,
      disponibilitaSelezionata: null
    };
  },
  mounted: function () {
    // this.jsonData.prossimiEsamiDisponibili = this.esamiDisponibili;
    console.log("àdfkmvgbdfpoòmgdùprfòomg")
    this.dataNascita = DateTime.fromISO(this.assistito.datiNascita.dataNascita).toFormat('dd/MM/yyyy')
    for (let proposta of this.prestazionePrimaDisponibilita){
      if(proposta){
        proposta.proposta.checked = false
      }
      console.log("propost", proposta)
    }
    this.loadData();
  },
  methods: {
    async onConfermaPrenotazione() {
      this.isLoading = true;
      const body = {
        codiceFiscale: this.assistito.codiceFiscale,
        idTransazione: this.disponibilitaSelezionata.proposta.idTransazione,
        codPrestazione: this.disponibilitaSelezionata.proposta.codPrestazione,
        desPrestazione: this.disponibilitaSelezionata.proposta.desPrestazione,
        data: this.disponibilitaSelezionata.proposta.data,
        ora: this.disponibilitaSelezionata.proposta.ora,
        dataFine: this.disponibilitaSelezionata.proposta.dataFine,
        oraFine: this.disponibilitaSelezionata.proposta.oraFine,
        nota: this.disponibilitaSelezionata.proposta.nota,
        nomeMedico: this.disponibilitaSelezionata.proposta.nomeMedico,
        importo: this.prestazioneSelezionata.importo,
        reparto: {
          codUnitaOp: this.disponibilitaSelezionata.proposta.reparto.codUnitaOp,
          desReparto: this.disponibilitaSelezionata.proposta.reparto.desReparto,
          desUnitaOp: this.disponibilitaSelezionata.proposta.reparto.desUnitaOp,
          codReparto: this.disponibilitaSelezionata.proposta.reparto.codReparto,
          ubicazioneReparto: this.disponibilitaSelezionata.proposta.reparto.ubicazioneReparto,
          ubicazioneUnitaOp: this.disponibilitaSelezionata.proposta.reparto.ubicazioneUnitaOp,
          latitudine: this.disponibilitaSelezionata.proposta.reparto.latitudine,
          longitudine: this.disponibilitaSelezionata.proposta.reparto.longitudine,
          importo: this.disponibilitaSelezionata.importo,
        }
      }
      const response = await PrenotazioniPrivate.confermaDisponibilita(this.prestazioneSelezionata.codstruttura, body)
      console.log("response", response)
      if(!response.data.error){
        // window.location.assign(response.data.data)
        this.isLoading = false;
        this.$bvModal.msgBoxOk(
            response.data.data,
            // "E' sicuro di voler prenotare? La prenotazione sarà confermata a seguito del pagamento che dovrà avvenire entro i prossimi 20 minuti. " +
            // 'Cliccando il pulsante "Conferma" sarà reindirizzato automaticamente alla pagina del pagamento. ' +
            // "Qualora il pagamento non dovesse andare a buon fine, la prenotazione sarà cancellata automaticamente. " +
            // "Le ricordiamo che il pagamento ha effetto immediato e non potrà essere rimborsato per via telematica. " +
            // "Per l’eventuale rimborso o per la cancellazione della prestazione sarà necessario rivolgersi alla struttura.",
            {
              title: "Conferma Prenotazione",
              footerClass: "p-2",
              centered: true,
              okTitle: "Conferma",
              noCloseOnBackdrop: true,
              noCloseOnEsc: true
            }
        )
            .then(() => {
              this.$emit("setStep", 0);
            })
            .catch(() => {});
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    getDisponibilitaSelezionata(disponibilita) {
      this.disponibilitaSelezionata = disponibilita;
    },
    deselect(index) {
      const list = [...this.prestazionePrimaDisponibilita]
      for (let i = 0; i<list.length; i++){
        if(i !== index){
          list[i].proposta.checked = false
        }
      }
      this.prestazionePrimaDisponibilita = list
      if(this.prestazionePrimaDisponibilita[index].proposta.checked){
        this.getDisponibilitaSelezionata(this.prestazionePrimaDisponibilita[index])
      } else {
        this.getDisponibilitaSelezionata(null)
      }
    },
    loadData() {
      this.token = localStorage.getItem("tokenWSO2");
      this.tokenLogin = localStorage.getItem("loginData");
    },
    onIndietro() {
      this.$emit("setStep", 0);
    },
    /*async onProssimaDisponibilita() {
      this.isLoading = true
      const time = moment(this.prestazionePrimaDisponibilita[0].proposta.ora, "HH:mm").toDate();
      let minutiPrest = moment(time).add(1, "minutes");
      let oraPrest = moment(minutiPrest).format("HH:mm");
      const body= {
        codprest: this.prestazioneSelezionata.codprest,
        codunitaop: this.prestazioneSelezionata.codunitaop,
        codreparto: this.prestazioneSelezionata.codreparto,
        dataOrario: this.prestazionePrimaDisponibilita[0].proposta.data + " " + oraPrest,
      }
      const response = await PrenotazioniPrivate.ricercaPrimaDisponibilita(this.prestazioneSelezionata.codstruttura, body)
      if(!response.data.error){
        this.isLoading = false;
        this.prestazionePrimaDisponibilita = [];
        response.data.data.importo = this.prestazioneSelezionata.importo;
        response.data.data.codstruttura = this.prestazioneSelezionata.codstruttura;
        this.prestazionePrimaDisponibilita.push(response.data.data);
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              //this.$router.replace("/#").catch((err) => {
              //  err;
              //});
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
      }
    },*/
    async getData(data) {
      this.isLoading = true
      const body= {
        codprest: this.prestazioneSelezionata.codprest,
        codunitaop: this.prestazioneSelezionata.codunitaop,
        codreparto: this.prestazioneSelezionata.codreparto,
        desbprest: this.prestazioneSelezionata.desbprest,
        desbunitaop: this.prestazioneSelezionata.desbunitaop,
        dataOrario: data + " 07:00"
      }
      const response = await PrenotazioniPrivate.ricercaPrimaDisponibilita(this.prestazioneSelezionata.codstruttura, body)
      if(!response.data.error){
        this.isLoading = false;
        this.prestazionePrimaDisponibilita = [];
        response.data.data.importo = this.prestazioneSelezionata.importo;
        response.data.data.codstruttura = this.prestazioneSelezionata.codstruttura;
        this.prestazionePrimaDisponibilita = response.data.data;
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
      }
    },
  },
};
</script>
<style>
.checkbox-disponibilita{
margin: 5px;
}
.checkbox-disponibilita input{
  display: none;
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  background-color: #11A7BE;
}
</style>
