<template>
  <b-navbar toggleable="lg" type="dark" class="sticky-top">
    <b-navbar-brand
      ><router-link to="prenota"> <img alt="Logo" :width="40" src="../../assets/logo_pic.png" /></router-link
    ></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item title="Prenota per te e i tuoi contatti"
          ><router-link to="prenota"><i class="fas fa-laptop-medical"></i> Prenotazione</router-link></b-nav-item
        >
        <b-nav-item title="Gestisci gli appuntamenti per te e i tuoi contatti"
          ><router-link to="listaappuntamenti"><i class="fas fa-clipboard-list"></i> Appuntamenti</router-link></b-nav-item
        >
        <!--<b-nav-item title="Visualizza la lista delle prestazioni prenotate"
        ><router-link to="storicoprenotazioni"><i class="fas fa-clock"></i> Storico Pagamenti</router-link></b-nav-item
        >-->
        <b-nav-item title="Aggiungi o modifica un contatto"
          ><router-link to="contatti"> <i class="fas fa-address-book"></i> Assistiti </router-link></b-nav-item
        >
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <!-- <b-nav-item-dropdown right>
          <template #button-content> <i class="fas fa-file-invoice-dollar"></i> Pagamenti </template>
          <b-dropdown-item title="Paga o consulta lo storico per te e i tuoi contatti"
            ><router-link to="listapendenzenre"><i class="fas fa-file-invoice-dollar"></i> Pagamenti da Effettuare</router-link></b-dropdown-item
          >
          <b-dropdown-item
            ><router-link to="storicopagamenti"><i class="fas fa-clock"></i> Storico Pagamenti</router-link></b-dropdown-item
          >
        </b-nav-item-dropdown> -->
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <i class="fas fa-user"></i>
          </template>
          <b-dropdown-item
            ><router-link to="profilo"><i class="fas fa-user"></i> Account Personale</router-link></b-dropdown-item
          >
          <!-- <b-dropdown-item href="#"><i class="fas fa-lock"></i> Modifica Password</b-dropdown-item> -->
          <b-dropdown-item href="#" @click="onRichiestaDati"><i class="fas fa-database"></i> Richiesta Dati</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item :href="linkTerminiServizio" target="_blank"><i class="fa fa-certificate"></i> Termini di Serivizio</b-dropdown-item>
          <b-dropdown-item
            ><router-link to="contattaci"><i class="fas fa-at"></i> Contattaci</router-link></b-dropdown-item
          >
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="onLogOut"><i class="fas fa-power-off"></i> Log out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
  </b-navbar>
</template>
<script>
import Loading from "vue-loading-overlay";
import Utenti from "@/services/Utenti";
import strutture from "@/classes/strutture";
export default {
  components: { Loading },
  data() {
    return {
      linkTerminiServizio: "resources/terminiServizio/cittadini.pdf",
      pathResourceRichiesta: "/api/v2.0/appecupt/auth/scaricaAnagrafica",
      tokenLogin: null,
      token: null,
      isLoading: false,
    };
  },
  mounted: function () {
    this.tokenLogin = localStorage.getItem("loginData");
    this.token = localStorage.getItem("tokenWSO2");
  },
  methods: {
    onLogOut() {
      localStorage.clear();
      this.$router.replace("/#").catch((err) => {
        err;
      });
    },
    async onRichiestaDati() {
      this.isLoading = true
      const response = await Utenti.recuperoInfoUtente(strutture[0].codice)
      if(!response.data.error){
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.data, {
              title: "Richiesta Dati",
              size: "md",
              okVariant: "outline-primary",
              headerClass: "vv-msg-header-primary",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {
              this.$emit("saveEdit");
              this.isLoading = false;
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
      } else {
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
      }
    },
  },
};
</script>
