import BaseService from "./BaseService";

export default class Registrazione extends BaseService {

    static async registrazione(idStruttura, anagrafica){
        return await this.perform({
            url: '/cittadini/registrazione/' + idStruttura,
            method: 'post',
            header:{},
            body: anagrafica
        })
    }

    static async controlloUtenza(body){
        return await this.perform({
            url: '/cittadini/registrazione/controlloDati/anagrafica',
            method: 'POST',
            body: body
        })
    }
}
