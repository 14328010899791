import vue from "vue";
import VueRouter from "vue-router";
import login from "../components/login/login.vue";
import registrati from "../components/registrati/registrati.vue";
import prenota from "../components/prenota/prenota.vue";
import proposte from "../components/prenota/proposte.vue";
import listaappuntamenti from "../components/appuntamenti/listaappuntamenti.vue";
import listaPendenze from "../components/pagamento/listaPendenze.vue";
import contatti from "../components/contatti/contatti.vue";
import contattaci from "../components/supporto/contattaci.vue";
import profilo from "../components/anagrafica/utente.vue";
import pagamentoConfermato from "../components/pagamento/pagamentoConfermato.vue";
import errorePagamento from "../components/pagamento/errorePagamento.vue";
import storicoPagamenti from "../components/pagamento/storicoPagamenti.vue";
import main from '../components/main/main.vue';
import RecuperaPassword from '../components/supporto/recuperaPassword.vue';
import RecuperaUsername from '../components/supporto/recuperaUsername.vue';
import storicoPrenotazioni from "@/components/storico/storicoPrenotazioni";

vue.use(VueRouter);

let mainChildren = [

  {
    path: "/profilo",
    name: "Profilo",
    component: profilo,
  },
  {
    path: '/prenota',
    name: 'Prenota',
    component: prenota,
  },
  {
    path: '/proposte',
    name: 'proposte',
    component: proposte
  },
  {
    path: '/listaappuntamenti',
    name: 'listaappuntamenti',
    component: listaappuntamenti,
  },
  {
    path: '/storicoprenotazioni',
    name: 'storicoprenotazioni',
    component: storicoPrenotazioni,
  },
  {
    path: '/listapendenzenre',
    name: 'listapendenzenre',
    component: listaPendenze
  },
  {
    path: '/contattaci',
    name: 'contattaci',
    component: contattaci
  },
  {
    path: '/storicopagamenti',
    name: 'storicopagamenti',
    component: storicoPagamenti,
  },
  {
    path: '/contatti',
    name: 'contatti',
    component: contatti,
    // beforeEnter: (to, from, next) => {
    //   var contattiIsEnable = JSON.parse(localStorage.getItem("serviziAttivi")).contattiIsEnable;
    //   if(contattiIsEnable) {
    //     next()
    //   } else {
    //     next(false)
    //   }
    // }

  },

];

const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
  },
  {
    path: '/home',
    component: main,
    children: mainChildren,
  },
  {
    path: '/registrati',
    name: 'Registrati',
    component: registrati,
  },
  {
    path: '/recuperapassword',
    name: 'recuperapassword',
    component: RecuperaPassword
  },
  {
    path: '/recuperausername',
    name: 'recuperausername',
    component: RecuperaUsername
  },
  {
    path: '/pagamentoconfermato',
    name: 'pagamentoconfermato',
    component: pagamentoConfermato,
  },
  {
    path: '/errorepagamento',
    name: 'errorepagamento',
    component: errorePagamento,
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('loginData')
    if (!token && to.name !== 'login' && to.name !== 'Registrati' && to.name !== 'recuperapassword' && to.name !== 'recuperausername' && to.name !== 'pagamentoconfermato' && to.name !== 'errorepagamento') {
      next({name: 'login'});
    } else {
      next();
    }
});

export default router
