<template>
  <div>
    <div class="mt-15 container card_container">
      <div class="mb-5 titolo">
        <h3 class="text-green mt-5 text-center"><i class="fas fa-clipboard-list"></i> Storico Pagamenti</h3>
      </div>
      <b-container class="bv-example-row">
        <b-row class="my-5">
          <b-col sm="12" class="mt-5">
            <b-form-select class="form-select" v-model="jsonData.assistito" :options="assistitoOptions" value-field="value" text-field="text" :value="null"></b-form-select>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="my-5 container card_container" v-if="jsonData.assistito != null">
      <b-card bg-variant="light">
        <b-container class="bv-example-row pt-lg-6">
          <b-row>
            <b-col sm class="mb-7">
              <span class="text-primary">Codice fiscale</span><br />
              <span>{{ jsonData.assistito.codiceFiscale }}</span>
            </b-col>
            <b-col sm class="mb-7">
              <span class="text-primary">Assistito</span><br />
              <span>{{ jsonData.assistito.nome }} {{ jsonData.assistito.cognome }}</span>
            </b-col>
            <b-col sm class="mb-7">
              <span class="text-primary">Data di nascita</span><br />
              <span>{{ dataNascita }}</span>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </div>
    <div v-if="jsonData.prenotazioni != null && jsonData.prenotazioni.length > 0">
      <div v-for="item in jsonData.prenotazioni" :key="item.idPrenotazione">
        <div class="mt-5 container card_container mb-5" v-if="jsonData.assistito.codiceFiscale">
          <b-card bg-variant="light" class="mb-5">
            <b-container class="bv-example-row pt-lg-6">
              <b-row>
                <b-col sm class="mb-7">
                  <span class="text-primary">Struttura</span><br />
                  <span>{{ item.nomeStruttura }}</span>
                </b-col>
                <b-col sm class="mb-7">
                  <span class="text-primary">Data prenotazione</span><br />
                  <span>{{ item.dataDiPrenotazione }}</span>
                </b-col>
                <b-col sm class="mb-7">
                  <span class="text-primary">Codice impegnativa</span><br />
                  <span>{{ item.nre }}</span>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
          <div>
            <b-button variant="outline-primary" class="mx-2 mb-2" @click="onScarica(item)" v-b-modal.pdfmodel><i class="fas fa-file-download fs-2x"></i> Scarica Fattura</b-button>
          </div>
          <div class="my-5">
            <datatable-storico-prenotazioni :item="item" :appuntamenti="item.appuntamenti" :assistito="jsonData.assistito" :idModal="item.idPrenotazione"></datatable-storico-prenotazioni>
          </div>
          <b-card bg-variant="light" border-variant="succprimaryess">
            <b-container class="bv-example-row">
              <b-row class="pt-lg-6">
                <b-col sm class="mb-7">
                  <span class="text-primary">Priorità:</span><span style="margin-left: 10px">{{ item.classePriorita }}</span>
                </b-col>
                <b-col sm class="mb-7">
                  <span class="text-primary">Tipo esenzione:</span>
                  <span style="margin-left: 10px">N/D</span>
                </b-col>
                <b-col sm class="mb-7">
                  <span class="text-primary">Importo:</span>
                  <span style="margin-left: 10px">{{ item.importo }}<span v-if="item.importo !== 'N/D' && item.importo !== 'Esente'">€</span></span>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </div>
      </div>
      <b-modal id="modal-spostamento" size="xl" hide-footer hide-header>
        <Spostamento
            :assistito="jsonData.assistito"
            :esamiDisponibili="esamiDisponibili"
            :prenotazione="item"
            @close="modalSpostamentoClose"
        ></Spostamento>
      </b-modal>
    </div>
    <div v-else-if="jsonData.prenotazioni != null && jsonData.prenotazioni.length === 0">
      <div class="mt-5 container card_container mb-5">
        <b-card bg-variant="light" class="mb-5">
          <b-container class="bv-example-row pt-lg-6">
            <b-row>
              <b-col sm class="mb-7 text-center">
                <strong>Non ci sono appuntamenti</strong>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </div>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
    <pdf-viewer :title="title" :pdf="jsonData.pdf"></pdf-viewer>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import pdfViewer from "../pdfViewer/pdfViewer.vue";
import strutture from "../../classes/strutture";
import Spostamento from "@/components/sposta/spostamento";
import UtilityMixin from "../../utilities/utilityMixin";
import Contatti from "@/services/Contatti";
import Utenti from "@/services/Utenti";
import ListaAppuntamenti from "@/services/ListaAppuntamenti";
import {DateTime} from "luxon";
import PrenotazioneNre from "@/services/PrenotazioneNre";
import Pagamento from "@/services/Pagamento";
import DatatableStoricoPrenotazioni from "@/components/datatable/datatableStoricoPrenotazioni";
import Storico from "@/services/Storico";

export default {
  mixins: [UtilityMixin],
  components: {
    DatatableStoricoPrenotazioni,
    Loading,
    pdfViewer,
    Spostamento
  },
  data() {
    return {
      pathResourceAppuntamenti: "/api/v2.0/appecupt/catomaior/listaappuntamenti",
      pathResourceContatti: "/api/v2.0/appecupt/auth/this",
      pathResourceInfoUtente: "/api/v2.0/appecupt/auth/prelevaAnagraficaUtente",
      pathResourceScarica: "/api/v2.0/appecupt/catomaior/stampaInvioRicevuta",
      pathResourceAnnulla: "/api/v2.0/appecupt/catomaior/annullaImpegnativa",
      pathResourceAnnullaPrivata: "/api/v2.0/appecupt/catomaior/private/annullaPrenotazione",
      assistitoOptions: [],
      jsonData: {
        assistito: null,
        prenotazioni: [],
        pdf: null,
      },
      item: null,
      dataNascita: '',
      token: null,
      tokenLogin: null,
      isLoading: false,
      listaStrutture: strutture,
      title: "Cedolino",
      esamiDisponibili: [],
    };
  },
  mounted: function () {
    this.token = localStorage.getItem("tokenWSO2");
    this.tokenLogin = localStorage.getItem("loginData");
    this.loadContatti();
  },
  watch: {
    "jsonData.assistito.codiceFiscale": function () {
      if (this.jsonData.assistito.codiceFiscale) {
        this.dataNascita = DateTime.fromISO(this.jsonData.assistito.datiNascita.dataNascita).toFormat('dd/MM/yyyy')
        this.loadAppuntamenti();
      }
    },
  },
  methods: {
    async loadContatti() {
      const response = await Contatti.getDatiContatti()
      if(!response.data.error){
        for(let i=0; i<response.data.data.length; i++)
          this.assistitoOptions.push({
            disabled: false,
            value: response.data.data[i],
            text: `${response.data.data[i].nome} ${response.data.data[i].cognome} - ${response.data.data[i].codiceFiscale}`
          })
        await this.loadInfoUtente();
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal.msgBoxOk(response.data.msg, {
          title: "Errore " + response.data.error,
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
      }
    },
    async loadInfoUtente() {
      const response = await Utenti.getDatiUtente()
      if(!response.data.error){
        this.assistitoOptions.unshift({
          disabled: false,
          value: response.data.data,
          text: `${response.data.data.nome} ${response.data.data.cognome} - ${response.data.data.codiceFiscale}`
        })
        this.jsonData.assistito = response.data.data
        await console.log(this.jsonData.assistito)
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal.msgBoxOk(response.data.msg, {
          title: "Errore " + response.data.error,
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
      }
    },
    async loadAppuntamenti() {
      this.isLoading = true;
      let totRichieste = 0;
      this.jsonData.prenotazioni = []
      for (const element of this.listaStrutture) {
        const response = await Storico.getStorico(element.codice, this.jsonData.assistito.codiceFiscale)
        if(!response.data.error){
          if (Array.isArray(response.data.data) && response.data.data.length > 0) {
            for (let i = 0; i < response.data.data.length; i++) {
              response.data.data[i].codiceStruttura = element.codice;
              response.data.data[i].nomeStruttura = element.nome;
            }
            this.jsonData.prenotazioni = this.jsonData.prenotazioni.concat(response.data.data);
          }
          totRichieste += 1;
          this.isLoading = totRichieste < this.listaStrutture.length ? true : false;
          this.jsonData.prenotazioni = this.jsonData.prenotazioni.sort((a, b) => DateTime.fromFormat(a.dataDiPrenotazione, 'dd/MM/yyyy').toMillis() -DateTime.fromFormat(b.dataDiPrenotazione, 'dd/MM/yyyy').toMillis())
          console.log(this.jsonData.prenotazioni)
        } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
          return this.$bvModal
              .msgBoxOk(response.data.msg, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
                okTitle: "Chiudi",
              })
              .then(() => {
                this.isLoading = false;
                localStorage.clear();
                window.location.assign('/')
                /*this.$router.replace("/#").catch((err) => {
                  err;
                });*/
              })
              .catch(() => {
                this.isLoading = false;
              });
        } else {
          this.isLoading = false;
          this.$bvModal.msgBoxOk(response.data.msg, {
            title: "Errore " + response.data.error,
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "vv-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
            okTitle: "Chiudi",
          })
              .then(() => {})
              .catch((err) => {
                console.log(err);
                this.isLoading = false;
              });
        }
      }
    },
    async onScarica(item) {
      this.isLoading = true;
      const body ={
        nre: item.nre,
        idTransazione: item.idTransazione,
        tipoDocumento: item.tipoDocumento,
        annoDocumento: item.annoDocumento,
        progDocumento: item.progDocumento
      }
      const response = await Storico.scaricaFattura(item.codiceStruttura, this.jsonData.assistito.codiceFiscale, body)
      if(!response.data.error){
        this.jsonData.pdf = response.data.data;
        this.isLoading = false;
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.isLoading = false;
        this.$bvModal.msgBoxOk(response.data.msg, {
          title: "Errore " + response.data.error,
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
      }
    },
    onCancella(item) {
      this.$bvModal
          .msgBoxConfirm("Sei sicuro di voler annullare l'appuntamento?", {
            title: "Conferma Annullamento",
            footerClass: "p-2",
            centered: true,
            okTitle: "Conferma",
            cancelTitle: "Annulla",
          })
          .then(async (response) => {
            if (response) {
              if (item.nre === "Prestazione Ambulatoriale") {
                this.isLoading = true;
                const body = {
                  reparto: {
                    codUnitaOp: item.appuntamenti[0].reparto.codUnitaOp,
                    annoPrenotazione: item.annoPrenotazione,
                    progPrenotazione: item.appuntamenti[0].progPrenotazione,
                    progCedolino: item.progCedolino,
                    desReparto: item.appuntamenti[0].reparto.desReparto
                  },
                  assistito: {codiceFiscale: this.jsonData.assistito.codiceFiscale},
                  appuntamento: {
                    desPrestazione: item.appuntamenti[0].desPrestazione,
                    dataAppuntamento: item.appuntamenti[0].dataAppuntamento,
                    oraAppuntamento: item.appuntamenti[0].oraAppuntamento,
                  },
                };
                const response = await ListaAppuntamenti.deletePrenotazionePrivata(item.codiceStruttura, body)
                if (!response.data.error) {
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Esito Annullamento",
                        size: "md",
                        okVariant: "outline-primary",
                        headerClass: "vv-msg-header-primary",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                        okTitle: "Chiudi",
                      })
                      .then(() => {
                        this.jsonData.prenotazioni = [];
                        this.loadAppuntamenti();
                      })
                      .catch(() => {
                      });
                  this.isLoading = false;
                }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Errore",
                        size: "md",
                        okVariant: "outline-danger",
                        headerClass: "vv-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                        okTitle: "Chiudi",
                      })
                      .then(() => {
                        this.isLoading = false;
                        localStorage.clear();
                        window.location.assign('/')
                        /*this.$router.replace("/#").catch((err) => {
                          err;
                        });*/
                      })
                      .catch(() => {
                        this.isLoading = false;
                      });
                } else {
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Errore Annullamento",
                        size: "md",
                        okVariant: "outline-danger",
                        headerClass: "vv-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                      })
                      .then(() => {
                        this.jsonData.prenotazioni = [];
                        this.loadAppuntamenti();
                      })
                      .catch(() => {
                      });
                  this.isLoading = false;
                }
              } else {
                this.isLoading = true;
                const body = {
                  nre: item.nre,
                  assistito: {
                    codiceFiscale: this.jsonData.assistito.codiceFiscale
                  },
                  appuntamenti:[]
                }
                for (let i = 0; i < item.appuntamenti.length; i++) {
                  body.appuntamenti.push({
                    desPrestazione: item.appuntamenti[i].desPrestazione,
                    dataAppuntamento: item.appuntamenti[i].dataAppuntamento,
                    oraAppuntamento: item.appuntamenti[i].oraAppuntamento,
                    reparto: {
                      desReparto: item.appuntamenti[i].reparto.desReparto,
                      ubicazioneReparto: item.appuntamenti[i].reparto.ubicazioneReparto,
                    },
                  })
                }
                const response = await PrenotazioneNre.eliminaPrenotazione(item.codiceStruttura, body)
                if (!response.data.error) {
                  this.isLoading = false;
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Esito Annullamento",
                        size: "md",
                        okVariant: "outline-primary",
                        headerClass: "vv-msg-header-primary",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                        okTitle: "Chiudi",
                      })
                      .then(() => {
                        this.jsonData.prenotazioni = [];
                        this.loadAppuntamenti();
                      })
                      .catch(() => {
                      });
                }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
                  this.$bvModal
                      .msgBoxOk(response.data.msg, {
                        title: "Errore",
                        size: "md",
                        okVariant: "outline-danger",
                        headerClass: "vv-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                        okTitle: "Chiudi",
                      })
                      .then(() => {
                        this.isLoading = false;
                        localStorage.clear();
                        window.location.assign('/')
                        /*this.$router.replace("/#").catch((err) => {
                          err;
                        });*/
                      })
                      .catch(() => {
                        this.isLoading = false;
                      });
                } else {
                  this.isLoading = false;
                  this.$bvModal
                      .msgBoxOk(response.data.msg+"\nCodice: " + response.data.code, {
                        title: "Errore Annullamento",
                        size: "md",
                        okVariant: "outline-danger",
                        headerClass: "vv-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                      })
                      .then(() => {
                      })
                      .catch(() => {
                      });
                }

              }
            }
          })
          .catch((error) => {
            this.isLoading = false;
            UtilityMixin.methods.errorAuthWso2(this, error.response);
            error;
          });
    },

    async onPaga(item) {
      const body = {
        nre: item.nre,
        cf: this.jsonData.assistito.codiceFiscale,
        struttura: item.codiceStruttura,
        datiPrenotazione: item
      }
      const response = await Pagamento.inizzializzaPagamentoNre(body)
      if (!response.data.error) {
        this.isLoading = false;
        await window.open(response.data.data)
      } else {
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      }
      window.location.reload()
    },

    sposta(esamiDisponibili, item){
      this.esamiDisponibili = esamiDisponibili
      this.item = item
      this.$bvModal.show('modal-spostamento')
    },
    async modalSpostamentoClose(){
      this.$bvModal.hide('modal-spostamento')
      location.reload()
    }
  },
};
</script>
<style>

#disabled-button-wrapper {
  display: inline-block; /* display: block works as well */
}

#disabled-button-wrapper  .btn[disabled] {
  /* don't let button block mouse events from reaching wrapper */
  pointer-events: none;
}
</style>
