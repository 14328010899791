<template>
  <div>
    <b-table
      striped
      hover
      :items="listaPrestazioni"
      responsive
      stacked="lg"
      :current-page="currentPage"
      :per-page="perPage"
      :fields="fieldsPrestazioni"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <div class="divcheck">
            <span aria-hidden="true">&check;</span>
          </div>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template v-slot:cell(importo)="row">
        <span>{{ row.item.importo }} €</span>
      </template>
      <!--<template v-slot:cell(medico)="row">
        <span>{{ row.item.nomemedico }} {{ row.item.cognomemedico }}</span>
      </template>
      <template v-slot:cell(actions)="row">
        <a
          v-if="row.item.codstruttura === '100001'"
          target="_blank"
          :href="linkMedicoPineta + row.item.nomemedico.toLowerCase() + '-' + row.item.cognomemedico.toLowerCase()"
          class="vv-link"
        >
          <i class="fas fa-info-circle"></i>
        </a>
      </template>-->
      <template v-slot:cell(codstruttura)="row">
        <span>{{ strutturaPineta(row.item.codstruttura) }}</span>
      </template>
    </b-table>
    <template>
      <b-row>
        <b-col cols="6">
          <strong class="text-primary"> Sono presenti {{ rows }} proposte</strong>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import UtilityMixin from "../../utilities/utilityMixin";

export default {
  mixins: [UtilityMixin],
  props: {
    listaPrestazioni: Array,
  },
  data() {
    return {
      fieldsPrestazioni: [
        {
          label: "Prestazione",
          key: "desbprest",
          sortable: true,
        },
        {
          label: "Ambulatorio",
          key: "desreparto",
        },
        /*{
          label: "Medico",
          key: "medico",
          sortable: true,
        },
        {
          label: "Prima Disponibilità",
          key: "datadisponibile",
          sortable: true,
        },*/
        {
          label: "Importo",
          key: "importo",
        },
        /*{
          label: "",
          key: "actions",
          thStyle: "width: 3rem",
        },*/
      ],
      currentPage: 1,
      perPage: 10,
      linkMedicoPineta: "https://pinetagrande.it/pinetagrande/medici/",
      prestazioneSelezionata: null,
    };
  },
  computed: {
    rows() {
      return this.listaPrestazioni.length;
    },
  },
  methods: {
    onRowSelected(item) {
      let me = this;
      me.prestazioneSelezionata = item[0];
      me.$emit("getPrestazioneSelezionata", me.prestazioneSelezionata);
    },
    // strutturaPineta(element) {
    //   return element === "150021"
    //     ? "Pineta Grande"
    //     : element === "150145"
    //     ? "Santa Rita"
    //     : element === "150909"
    //     ? "Villa Bianca"
    //     : element === "150423"
    //     ? "Padre Pio"
    //     : "Villa Esther";
    // },
  },
};
</script>
