<template>
  <div>
    <b-card bg-variant="light" class="my-5">
      <b-container class="bv-example-row mt-10">
        <b-row>
          <b-col sm class="mb-7">
            <span class="text-primary">Codice fiscale</span><br />
            <span>{{ assistito.codiceFiscale }}</span>
          </b-col>
          <b-col sm class="mb-7">
            <span class="text-primary">Codice impegnativa</span><br />
            <span>{{ codiceImpegnativa }}</span>
          </b-col>
          <b-col sm class="mb-7">
            <span class="text-primary">Codice priorità</span><br />
            <span>{{ priorita }}</span>
          </b-col>
          <b-col sm class="mb-7">
            <span class="text-primary">Esenzione</span><br />
            <span>Non esente</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    codiceImpegnativa: String,
    priorita: String,
    assistito: Object,
  },
  data() {
    return {
      jsonData: {},
    };
  },
  created: function () {},
  methods: {},
};
</script>
