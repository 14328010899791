import BaseService from "./BaseService";

export default class Spostamento extends BaseService {

    static async ricercaDisponibilitaNre(idStruttura, body){
        return await this.perform({
            url: '/catomaior/nre/spostamento/primadisponibilita/'+idStruttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async prossimaDisponibilitaNre(idStruttura, body){
        return await this.perform({
            url: '/catomaior/nre/spostamento/prossimadisponibilita/'+idStruttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async prossimaDisponibilitaDataNre(idStruttura, body){
        return await this.perform({
            url: '/catomaior/nre/spostamento/disponibilitadata/'+idStruttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async confermaSpostamentoNre(idStruttura, body){
        return await this.perform({
            url: '/catomaior/nre/spostamento/conferma/'+idStruttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async ricercaDisponibilitaPrivata(idStruttura, body){
        return await this.perform({
            url: '/catomaior/privata/spostamento/disponibilita/'+idStruttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async confermaSpostamentoPrivata(idStruttura, body){
        return await this.perform({
            url: '/catomaior/privata/spostamento/conferma/'+idStruttura,
            method: 'post',
            headers: {},
            body: body
        })
    }
}
