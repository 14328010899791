<template>
  <div>
    <div>
      <b-container class="bv-example-row">
        <div class="mt-15 container card_container">
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px"><strong>Nome</strong></div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="nome"
                placeholder="Nome"
                value=""
                v-model="jsonData.nome"
                style="text-transform: uppercase"
                disabled
              />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Cognome</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="cognome"
                placeholder="Cognome"
                value=""
                v-model="jsonData.cognome"
                style="text-transform: uppercase"
                disabled
              />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Codice fiscale</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="cf"
                placeholder="Codice Fiscale"
                value=""
                v-model="jsonData.codiceFiscale"
                style="text-transform: uppercase"
                disabled
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px"><strong>Data di nascita</strong></div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="datanascita"
                placeholder="Data di nascita"
                value=""
                v-model="jsonData.datiNascita.dataNascita"
                locale="it"
                disabled
              />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Luogo di nascita</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="luogonascita"
                value=""
                v-model="jsonData.datiNascita.luogoNascita"
                style="text-transform: uppercase"
                disabled
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Sesso</strong>
              </div>
              <b-form-select class="form-select" v-model="jsonData.sesso" :options="sessoOptions" disabled> </b-form-select>
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Stato civile</strong>
              </div>
              <b-form-select class="form-select" v-model="jsonData.statoCivile" :options="statoCivileOptions" value-field="id" text-field="descrizione"></b-form-select>
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Provincia di residenza</strong>
              </div>
              <b-form-select
                class="form-select"
                v-model="jsonData.datiResidenza.provinciaResidenza"
                :options="provinciaResidenzaOptions"
                value-field="value"
                text-field="label"
                @change="getSelectedProvinciaResidenza"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong> Comune di residenza</strong>
              </div>
              <b-form-select
                class="form-select"
                v-model="jsonData.datiResidenza.comuneResidenza.codiceIstat"
                :value="null"
                value-field="codiceIstat"
                :options="comuniResidenzaOptions"
                :state="validateComuneResidenza"
                text-field="descrizione"
                style="text-transform: uppercase"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>Seleziona il comune</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Indirizzo di residenza</strong>
              </div>
              <b-form-input
                v-model="jsonData.datiResidenza.indirizzoResidenza"
                :value="null"
                value-field="value"
                text-field=""
                :state="validateIndirizzoResidenza"
                style="text-transform: uppercase"
                type="text"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row v-if="nazionalitaStraniera">
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Nazionalità</strong>
              </div>
              <b-form-select class="form-select" v-model="jsonData.cittadinanza" :options="cittadinanzaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Cellulare</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="provincianascita"
                placeholder="Cellulare"
                value=""
                required
                maxlength="10"
                :state="validationCellulare"
                v-model="jsonData.telefono"
              />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Telefono fisso</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="provincianascita"
                placeholder="Telefono fisso"
                value=""
                required
                maxlength="11"
                :state="validationFisso"
                v-model="jsonData.telefonoFisso"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Indirizzo Email</strong>
              </div>
              <b-form-input
                  type="text"
                  class="form-control form-control-solid"
                  name="provincianascita"
                  placeholder="Indirizzo Email"
                  value=""
                  :state="validateMail"
                  v-model="jsonData.email"
              />
            </b-col>
          </b-row>
          <div class="footer d-flex justify-content-end">
            <b-button variant="light" class="text-primary" @click="onIndietro"> <i class="fas fa-angle-double-left"></i> Indietro </b-button>

            <b-button variant="primary" class="ml-2" @click="onModifica"> <i class="fas fa-check"></i> Salva </b-button>
          </div>
        </div>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import UtilityMixin from "../../utilities/utilityMixin";
import {DateTime} from "luxon";
import Utils from "@/services/Utils";
import Contatti from "@/services/Contatti";
export default {
  components: { Loading },
  mixins: [UtilityMixin],
  props: {
    contatto: Object,
  },
  data() {
    return {
      jsonData: {
        nome: this.contatto.nome,
        cognome: this.contatto.cognome,
        codiceFiscale: this.contatto.codiceFiscale,
        datiNascita: this.contatto.datiNascita,
        datiResidenza: {
          provinciaResidenza:{
            label: this.contatto.datiResidenza.provinciaResidenza,
            value: this.contatto.datiResidenza.codIstatProvinciaResidenza
          },
          comuneResidenza: {
            codiceIstat: this.contatto.datiResidenza.istatComuneResidenza,
            descrizione: this.contatto.datiResidenza.comuneResidenza,
          },
          indirizzoResidenza: this.contatto.datiResidenza.indirizzoResidenza
        },
        sesso: this.contatto.sesso,
        telefono: this.contatto.telefono,
        telefonoFisso: this.contatto.telefonoFisso,
        email: this.contatto.email,
        statoCivile: {
          id: this.contatto.codStatoCivile,
          descrizione: this.contatto.statoCivile
        }
      },
      sessoOptions: [
        { value: "M", text: "MASCHIO" },
        { value: "F", text: "FEMMINA" },
      ],
      provinciaResidenzaOptions: [],
      cittadinanzaOptions: [{ value: null, text: "Seleziona la cittadinanza", disabled: true }],
      comuniResidenzaOptions: [],
      statoCivileOptions: [],
      pathResourceListaProvince: "/api/v2.0/appecupt/comuni/listaprovince",
      pathResourceStatoCivile: "/api/v2.0/appecupt/statocivile",
      pathResourceListaComuni: "/api/v2.0/appecupt/comuni/listacomuni",
      pathResourceListaNazioni: "/api/v2.0/appecupt/nazioni/",
      pathResourceModificaContatto: "/api/v2.0/appecupt/auth/modAssistito",
      token: null,
      nazionalitaStraniera: false,
      isLoading: false,
    };
  },
  computed: {
    validationCellulare() {
      if(!this.jsonData.telefono && !this.jsonData.telefonoFisso)
        return false
      var regexCellulare = /^3\d{8,9}$/;
      return !this.jsonData.telefono ? true : regexCellulare.test(this.jsonData.telefono);
    },
    validationFisso() {
      if(!this.jsonData.telefono && !this.jsonData.telefonoFisso)
        return false
      let regexFisso = /^0\d+$/;
      return !this.jsonData.telefonoFisso ? true : regexFisso.test(this.jsonData.telefonoFisso);
    },
    validateMail(){
      if(this.jsonData.email){
        let regexMail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return regexMail.test(this.jsonData.email)
      }
      return true
    },
    validateComuneResidenza() {
      return !this.jsonData.datiResidenza.comuneResidenza.codiceIstat ? false : true;
    },
    validateIndirizzoResidenza() {
      return !this.jsonData.datiResidenza.indirizzoResidenza ? false : true;
    }
  },
  mounted: function () {
    this.token = localStorage.getItem("tokenWSO2");
    this.tokenLogin = localStorage.getItem("loginData");
    this.loadListaProvince();
    this.jsonData.datiNascita.dataNascita = DateTime.fromISO(this.jsonData.datiNascita.dataNascita).toFormat('dd/MM/yyyy')
  },
  methods: {
    async loadListaProvince() {
      const response = await Utils.getProvince()
      if(!response.data.error){
        for (let i=0; i<response.data.data.length; i++){
          this.provinciaResidenzaOptions.push({
            value: response.data.data[i],
            label: response.data.data[i].label.toUpperCase()
          })
        }
        if (this.jsonData.datiResidenza.comuneResidenza.codiceIstat) await this.getSelectedProvinciaResidenza(null, true);
        await this.loadStatoCivile();
        await this.controlloNazionalita();
        //await this.getSelectedProvinciaResidenza();
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        console.log(response.data.msg)
      }
    },
    async loadStatoCivile() {
      const response = await Utils.getStatoCivile()
      if(!response.data.error){
        for(let i=0; i<response.data.data.length; i++){
          this.statoCivileOptions.push({
            id: response.data.data[i],
            descrizione: response.data.data[i].descrizione
          })
        }
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        console.log(response.data.msg)
      }
    },
    async getSelectedProvinciaResidenza(item, primaVolta = false) {
      this.disableComuneResidenza = false
      if (!primaVolta) {
        this.jsonData.datiResidenza.comuneResidenza = {
          codiceIstat: null,
          descrizione: null
        }
      }
      this.comuniResidenzaOptions = [];
      const response = await Utils.getComuni(this.jsonData.datiResidenza.provinciaResidenza.value, null)
      if(!response.data.error){
        for(let i=0; i< response.data.data.length; i++){
          this.comuniResidenzaOptions.push({
            codiceIstat: response.data.data[i].codiceIstat,
            descrizione: response.data.data[i].descrizione
          })
        }
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        console.log(response.data.msg)
      }
    },
    async controlloNazionalita() {
      const response = await Utils.getNazioni()
      if(!response.data.error){
        for(let i=0; i<response.data.data.length; i++){
          this.cittadinanzaOptions.push({
            disabled: false,
            value: response.data.data[i],
            text: response.data.data[i].descrizione.toUpperCase()
          })
        }
      } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        console.log(response.data.msg)
      }
    },
    async onModifica() {
      if(this.validationCellulare && this.validationFisso && this.validateMail && this.validateComuneResidenza && this.validateIndirizzoResidenza){
        const datiRegistrazione = {
          datiResidenza:{
            istatComuneResidenza: this.jsonData.datiResidenza.comuneResidenza.codiceIstat,
            codIstatProvinciaResidenza: this.jsonData.datiResidenza.provinciaResidenza.value,
            indirizzoResidenza: this.jsonData.datiResidenza.indirizzoResidenza
          },
          telefono: this.jsonData.telefono,
          telefonoFisso: this.jsonData.telefonoFisso,
          email: this.jsonData.email,
          codStatoCivile: this.jsonData.statoCivile.id,
          statoCivile: this.jsonData.statoCivile.descrizione,
          /*cittadinanza: {
            codCittadinanza: this.contatto.codiceFiscale.substr(11, 1) === "Z" ? this.contatto.cittadinanza.codCittadinanza : this.contatto.nazionalita.codiceNazionalita,
            desCittadinanza: this.contatto.codiceFiscale.substr(11, 1) === "Z" ? this.contatto.cittadinanza.desCittadinanza : "ITALIA",
          },*/
        };
        if(datiRegistrazione.telefono || datiRegistrazione.telefonoFisso){
          this.isLoading = true;
          const response = await Contatti.modificaContatto(this.jsonData.codiceFiscale, datiRegistrazione)
          if(!response.data.error){
            this.isLoading = false;
            this.$bvModal
                .msgBoxOk(response.data.msg, {
                  title: "Congratulazioni",
                  size: "md",
                  okVariant: "outline-primary",
                  headerClass: "vv-msg-header-primary",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                })
                .then(() => {
                  this.$emit("saveEdit");
                  this.isLoading = false;
                })
                .catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                });
          } else if (response.data.code === 'ERR_CHECK_AUTH_2'){
            this.$bvModal
                .msgBoxOk(response.data.msg, {
                  title: "Errore",
                  size: "md",
                  okVariant: "outline-danger",
                  headerClass: "vv-msg-header-danger",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                  okTitle: "Chiudi",
                })
                .then(() => {
                  this.isLoading = false;
                  localStorage.clear();
                  window.location.assign('/')
                  /*this.$router.replace("/#").catch((err) => {
                    err;
                  });*/
                })
                .catch(() => {
                  this.isLoading = false;
                });
          } else {
            this.$bvModal
                .msgBoxOk(response.data.msg, {
                  title: "Errore",
                  size: "md",
                  okVariant: "outline-danger",
                  headerClass: "vv-msg-header-danger",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                })
                .then(() => {})
                .catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                });
            this.isLoading = false;
          }
        }
        this.isLoading = false;
      }
    },
    onIndietro() {
      this.$emit("saveEdit");
    },
  },
};
</script>
