<template>
  <div>
    <b-modal
      id="modal-5"
      class="modal-content"
      title="Scegli la data di ricerca"
      @ok="onRicercaDisponibilita"
      slot="modal-header"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      cancel-title="Chiudi"
      ok-title="Cambia Data"
    >
      <b-container class="bv-example-row">
        <b-row>
          <b-col>
            <b-calendar
              block
              v-model="dataSelezionata"
              :initial-date="min"
              :min="min"
              locale="it"
            ></b-calendar>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: Array
  },
  data() {
    return {
      value: "",
      min: new Date(),
      dataSelezionata: null,
    };
  },
  methods: {
    onRicercaDisponibilita() {
      let me = this;
      me.dataricerca = moment(me.dataSelezionata).format("DD/MM/YYYY");
      me.$emit("getData", me.dataricerca);
    },
  },
  watch: {
    data: function (){
      this.min = moment(this.data[0].proposta.data, 'DD/MM/YYYY').toDate()
    }
  }
}
</script>
