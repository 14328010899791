import BaseService from "./BaseService";

export default class PrenotazioneNre extends BaseService {

    static async presaInCarico(body, struttura){
        return await this.perform({
            url: '/catomaior/nre/prenotazione/ricetta/'+ struttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async controlloPrestazioni(idStruttura){
        return await this.perform({
            url: '/catomaior/nre/prenotazione/controlloprestazioni/'+ idStruttura,
            method: 'get',
            headers: {}
        })
    }

    static async primaDisponibilita(idStruttura, body){
        return await this.perform({
            url: '/catomaior/nre/prenotazione/primadisponibilita/'+ idStruttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async prossimaDisponibilita(idStruttura){
        return await this.perform({
            url: '/catomaior/nre/prenotazione/prossimadisponibilita/'+ idStruttura,
            method: 'get',
            headers: {}
        })
    }

    static async prossimaDisponibilitaData(idStruttura, data){
        return await this.perform({
            url: '/catomaior/nre/prenotazione/disponibilitadata/'+ idStruttura,
            method: 'get',
            headers: {},
            params: { dataricerca: data}
        })
    }

    static async annullaPrenotazione(idStruttura, cf){
        return await this.perform({
            url: '/catomaior/nre/prenotazione/temp/annulla/'+ idStruttura,
            method: 'post',
            headers: {},
            body: { codiceFiscale: cf}
        })
    }

    static async eliminaPrenotazione(idStruttura, body){
        return await this.perform({
            url: '/catomaior/nre/prenotazione/annulla/'+ idStruttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async confermaPrenotazione(idStruttura, body){
        return await this.perform({
            url: '/catomaior/nre/prenotazione/conferma/'+ idStruttura,
            method: 'post',
            headers: {},
            body: body
        })
    }
}
