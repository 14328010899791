<template>
  <div>
    <div class="mt-15 container card_container">
      <h3 class="mb-5 titolo"><i class="fas fa-at"></i> Supporto</h3>
      <!--<lista-strutture-pineta-check @setStruttura="setStruttura"></lista-strutture-pineta-check>-->
      <b-container class="bv-example-row">
        <b-row>
          <b-col sm class="mb-7 mt-5">
            <div class="h-35px">
              <strong class="text-primary">Assistito</strong>
            </div>
            <b-form-select class="form-select" v-model="jsonData.assistito" :options="assistitoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="bv-example-row">
        <b-row>
          <b-col sm class="mb-7">
            <span class="text-primary">Messaggio</span><br />
            <b-form-textarea v-model="jsonData.messaggio" debounce="500" class="form-control"></b-form-textarea>
          </b-col>
        </b-row>
      </b-container>
      <div class="footer d-flex justify-content-end">
        <b-button variant="outline-primary" class="ml-2 btn-outline" @click="onInvia" :disabled="invioDisabilitato"> <i class="fas fa-paper-plane"></i> Invia </b-button>
      </div>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import UtilityMixin from "../../utilities/utilityMixin.js";
import strutture from "../../classes/strutture";
import Contatti from "@/services/Contatti";
import Utenti from "@/services/Utenti";
import Utils from "@/services/Utils";

export default {
  mixins: [UtilityMixin],
  components: {
    Loading,
  },
  data() {
    return {
      pathResourceInviaMessaggio: "/urlserviziapp/inviaMailAssistenza",
      jsonData: {
        assistito: {},
      },
      isLoading: false,
      tokenLogin: null,
      token: null,
      invioDisabilitato: true,
      assistitoOptions: [],
      listaStrutture: strutture,
      struttureSelezionate: ['100001'],
    };
  },
  mounted: function () {
    this.tokenLogin = localStorage.getItem("loginData");
    this.token = localStorage.getItem("tokenWSO2");
    this.loadContatti();
    this.loadInfoUtente();
  },
  methods: {
    async loadContatti() {
      const response = await Contatti.getDatiContatti()
      if(!response.data.error){
        for(let i=0; i<response.data.data.length; i++)
          this.assistitoOptions.push({
            disabled: false,
            value: response.data.data[i],
            text: `${response.data.data[i].nome} ${response.data.data[i].cognome} - ${response.data.data[i].codiceFiscale}`
          })
      } else {
        this.isLoading = false;
        this.$bvModal.msgBoxOk(response.data.msg, {
          title: "Errore " + response.data.error,
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
      }
    },
    async loadInfoUtente() {
      const response = await Utenti.getDatiUtente()
      if(!response.data.error){
        this.assistitoOptions.unshift({
          disabled: false,
          value: response.data.data,
          text: `${response.data.data.nome} ${response.data.data.cognome} - ${response.data.data.codiceFiscale}`
        })
        this.jsonData.assistito = response.data.data
      } else {
        this.isLoading = false;
        this.$bvModal.msgBoxOk(response.data.msg, {
          title: "Errore " + response.data.error,
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
      }
    },
    async onInvia() {
      this.isLoading = true;
      /*if(this.struttureSelezionate.length === 0){
        this.$bvModal
            .msgBoxOk('Bisogna selezionare una struttura', {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {
              this.isLoading = false;
              return null
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
            });
        return
      }*/
      const body = {
        codiceFiscaleAssistito: this.jsonData.assistito.codiceFiscale,
        strutture: this.struttureSelezionate,
        messaggio: this.jsonData.messaggio
      }
      const response = await Utils.richiestaAssistenza(body)
      if(!response.data.error){
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Invio Richiesta Supporto",
              size: "md",
              okVariant: "outline-primary",
              headerClass: "vv-msg-header-primary",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {
              this.isLoading = false;
              this.$router.push('/prenota')
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
            });
      } else {
        this.isLoading = false;
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
            });
      }
    },
    setStruttura(item) {
      this.struttureSelezionate = {};
      this.struttureSelezionate = item;
    },
  },
  watch: {
    "jsonData.messaggio": function (value) {
      if (value.length !== 0) {
        this.invioDisabilitato = false;
      } else {
        this.invioDisabilitato = true;
      }
    },
  },
};
</script>
