<template>
  <b-modal
      size="lg"
      id="modalCambioPassword"
      title="Cambio Password"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      ref="modalCambioPassword"
      centered
      @ok="update"
      @show="refreshDate"
      okTitle="Conferma"
      cancelTitle="Annulla"
  >
    <b-form-row class="mb-8">
        <strong>Password Corrente</strong>
        <b-form-input type="password" v-model="pwd.password" id="password1" :disableStrength="true" />
    </b-form-row>
    <b-form-row class="mb-4">
      <strong>Nuova Password</strong>
      <b-form-input type="password" v-model="pwd.nuovaPassword" :state="validatePwd1" id="password1" :disableStrength="true" />
    </b-form-row>
    <b-form-row class="mb-4">
      <strong>Ripeti Nuova Password</strong>
      <b-form-input type="password" v-model="pwd.nuovaPassword2" :state="validatePwd2" id="password1" :disableStrength="true" />
    </b-form-row>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      pwd: {
        password: '',
        nuovaPassword: '',
        nuovaPassword2: ''
      },
      regexSpazio: /^\S*$/,
    };
  },
  computed: {
    validatePwd1(){
      var regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
      return this.pwd.nuovaPassword.length === 0 ? null : regexPassword.test(this.pwd.nuovaPassword) && this.regexSpazio.test(this.pwd.nuovaPassword);
    },
    validatePwd2(){
      return this.pwd.nuovaPassword2.length === 0 ? null : this.pwd.nuovaPassword2 === this.pwd.nuovaPassword;
    }
  },
  methods: {
    show() {
      this.$refs["modalCambioPassword"].show();
    },
    update() {
      this.$emit("update", this.pwd);
    },
    refreshDate(){
      this.pwd.password = ''
      this.pwd.nuovaPassword = ''
      this.pwd.nuovaPassword2 = ''
    }
  },
};
</script>

<style scoped>

</style>
