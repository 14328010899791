<template>
  <div>
    <b-table striped hover :items="appuntamenti" responsive stacked="lg" :fields="fieldsAppuntamenti" ok-only @hide="resetModalNota">
      <template v-slot:cell(dataEora)="data">
        {{ data.item.dataAppuntamento }}
        {{ data.item.oraAppuntamento }}
      </template>
      <template v-slot:cell(actions)="row">
        <a v-if="row.item.booleanNota" class="vv-link" @click="info(row.item, row.index)">
          <i class="fas fa-info-circle"></i>
        </a>
        <span v-else>N/D</span>
      </template>
<!--      <template v-if="appuntamenti[0].reparto.desReparto" v-slot:cell(indicazioniReparto)="row">
        <span v-if="appuntamenti[0].reparto.desReparto"> {{ row.item.reparto.desReparto }}</span>
        <span v-else>
          {{ row.ubicazioneReparto }}
        </span>
      </template>-->
      <!--<template v-slot:cell(sposta)="appuntamento">
        <div id="disabled-button-wrapper" v-b-tooltip="item.messaggioSpostamento">
          <b-button variant="outline-success" :disabled="!item.abilitaSpostamento" class="mx-2 mb-2" @click="onSposta(appuntamento)"><i class="fas fa-angle-right"></i> Sposta</b-button>
        </div>
      </template>-->
    </b-table>
    <b-modal :id="'modalNota_' + idModal" class="modal-content" title="Nota Appuntamento" slot="modal-header" hide-header-close @hide="resetModalNota" size="lg">
      <b-container>
        <b-row>
          <b-col>
            <p class="vv-text" v-html="modalNota.content"></p>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    appuntamenti: Array,
    assistito: Object,
    idModal: Number,
    item: Object
  },
  data() {
    return {
      token: null,
      tokenLogin: null,
      appuntamentoDaSpostare: Object,
      esamiDisponibili: [],
      fieldsAppuntamenti: [
        {
          label: "Prestazione",
          key: "desPrestazione",
        },
        {
          label: "Data E Ora",
          key: "dataEora",
        },
        {
          label: "Ambulatorio",
          key: "reparto.desUnitaOp",
        },
        // {
        //   label: "Indicazioni Reparto",
        //   //key: "reparto.ubicazioneReparto",
        //   key: "indicazioniReparto",
        // },
        {
          label: "Nota",
          key: "actions",
        },
        /*{
          label: " ",
          key: "sposta"
        }*/
      ],
      modalNota: { title: "", content: "" },
    };
  },
  mounted: function () {
    //console.log(this.item);
  },
  methods: {
    info(item) {
      let me = this;
      if (item.booleanNota) {
        me.modalNota.content =  item.nota;
      } else {
        me.modalNota.content = "Non è presente nessuna Nota";
      }
      this.$bvModal.show('modalNota_' + this.idModal);
    },
    resetModalNota() {
      let me = this;
      me.modalNota.content = "";
    },
    onSposta(appuntamento){
      this.esamiDisponibili = []
      this.esamiDisponibili.push(appuntamento.item)
      this.$emit("sposta", this.esamiDisponibili, this.item)
    }
  },
};
</script>
<style>
.vv-text {
  text-align: justify;
}
.vv-link:hover {
  color: #055f2f;
}
</style>
