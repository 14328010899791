export default [
  {
    nome: "Catomaior",
    codice: "100001"
  },
  // {
  //   nome: "Sanatrix",
  //   codice: "150907"
  // },
  /*{
    nome: "Villa Bianca",
    codice: "150106"
  },
  {
    nome: "Padre Pio",
    codice: "150423"
  },
  {
    nome: "Villa Esther",
    codice: "150143"
  },
  {
    nome: "Santa Rita",
    codice: "150145"
  }*/
]
