import BaseService from "./BaseService";

export default class RecuperoCredenziali extends BaseService {

    static async cambioPasswordAuth(body){
        return await this.perform({
            url: '/cittadini/recuperoCredenziali/password/auth/modifica',
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async cambioPasswordRichiesta(idStruttura, email){
        return await this.perform({
            url: '/cittadini/recuperoCredenziali/password/richiesta/' + idStruttura,
            method: 'post',
            headers: {},
            body: {email: email}
        })
    }

    static async verificaPin(pin, email) {
        return await this.perform({
            url: '/cittadini/recuperoCredenziali/password/',
            method: 'post',
            headers: {},
            body: {
                email: email,
                pin: pin
            }
        })
    }

    static async cambioPassword(body){
        return await this.perform({
            url: '/cittadini/recuperoCredenziali/password/modifica',
            method: 'post',
            headers: {},
            body: body
        })
    }
}
