<template>
  <div>
    <b-container class="mt-10">
      <b-row>
        <b-col sm class="text-center">
          <img alt="Logo" src="../../assets/logo.png" class="h-150px my-9" />
        </b-col>
      </b-row>
    </b-container>
    <div class="container card_container p-10 p-lg-5 mx-auto phone">
      <b-container class="bv-example-row">
        <b-row>
          <b-col sm class="mx-auto">
            <h4 class="text-center mb-5 mt-5">ACCEDI</h4>
          </b-col>
        </b-row>
        <b-row>
					<b-col sm class="mb-5 text-center">
						Sei un nuovo utente?
						<router-link class="link-primary fw-bolder" to="/registrati" >Crea un Account</router-link>
					</b-col>
        </b-row>
        <b-row>
          <b-col sm class="mb-5">
            <strong>Email</strong>
            <input class="form-control form-control-sm" type="text" placeholder="" name="user" required v-on:keyup.enter="onAccedi" v-model="loginData.email" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <strong>Password</strong>
            <VuePassword v-model="loginData.password" id="password1" :disableStrength="true" v-on:keyup.enter="onAccedi" />
          </b-col>
        </b-row>
        <b-row style="height: 40px">
          <b-col sm class="my-5">
            <a v-b-modal.modalPassword class="link-primary fs-6 fw-bolder">Password dimenticata?</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm class="mb-5 text-center">
            <a>
              <b-button variant="primary" size="lg" class="w-100" @click="onAccedi" style="margin-top: 20px"> Accedi </b-button>
            </a>
          </b-col>
        </b-row>
      </b-container>
      <modal-recuper-password></modal-recuper-password>
      <b-modal ref="modalTermini" title="Termini di Servizio" size="xl" ok-title="Accetta" cancel-title="Rifiuta" @ok="AccettaTermini">
        <b-row style="height: 600px">
            <iframe class="frameTermini" src="http://192.168.125.174:3004/cittadini/terminiServizio"/>
        </b-row>
      </b-modal>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
  </div>
</template>
<script>
import router from "../../router/router";
import VuePassword from "vue-password";
import ModalRecuperPassword from "../modal/modalRecuperPassword.vue";
import Loading from "vue-loading-overlay";
import Login from "@/services/Login";
import strutture from "@/classes/strutture";
//import UtilityMixin from "@/utilities/utilityMixin";
export default {
  components: {ModalRecuperPassword, VuePassword, Loading },
  data() {
    return {
      data: {},
      loginData: {
        email: null,
        password: null,
      },
      username: null,
      password: null,
      isLoading: false,
      boolTermini: false,
      terminiServizio: false
    };
  },
  created: function () {
  },
  mounted: function () {
    //UtilityMixin.methods.loginWso2();
  },
  methods: {
    async onAccedi() {
      let regexMail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!regexMail.test(this.loginData.email) || !this.loginData.password) {
        return this.$bvModal
          .msgBoxOk("Inserisca mail e password", {
            title: "Errore Login",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "vv-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
            okTitle: "Chiudi",
          })
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }
      this.isLoading = true;
      try {
        const response = await Login.login(strutture[0].codice, this.loginData)
        if(!response.data.error){
          localStorage.setItem("loginData", response.data.data.token);
          localStorage.setItem(
              "messaggiUtente",
              JSON.stringify({
                messaggioPrePagamento: response.data.data.messaggioPrePagamento,
                messaggioPrePrenotazione: response.data.data.messaggioPrePrenotazione,
                messaggioPrePrenotazionePrivata: response.data.data.messaggioPrePrenotazionePrivata,
                piuReparti: response.data.data.piu_reparti,
                preAnnullamento: response.data.data.pre_annullamento,
              }),
              localStorage.setItem(
                  "serviziAttivi",
                  JSON.stringify({
                    prenotazionePending: response.data.data.prenotazionePending,
                    loginStruttura: response.data.data.loginStruttura,
                    visualizzaTermini: response.data.data.visualizzaTermini,
                    contattiIsEnable: response.data.data.contattiIsEnable,
                    servizioIsEnable: response.data.data.servizioIsEnable,
                    servizioWebIsEnable: response.data.data.servizioWebIsEnable,
                    pagamentiIsEnable: response.data.data.pagamentiIsEnable,
                    refertiIsEnable: response.data.data.refertiIsEnable,
                    prenotazioneIsEnable: response.data.data.prenotazioneIsEnable,
                    appuntamentiIsEnable: response.data.data.appuntamentiIsEnable,
                    cambioOperatore: response.data.data.cambioOperatore,
                    alpi: response.data.data.alpi,
                  })
              )
          );
          this.isLoading = false;
          if(response.data.data.visualizzaTermini === true){
            this.$refs.modalTermini.show();
          } else {
            await router.push({
              name: "Prenota",
              query: {authToken: response.data.access_token},
            });
          }
        } else {
          this.isLoading = false;
          await this.$bvModal.msgBoxOk(response.data.msg, {
            title: "Errore Login",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "vv-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
            okTitle: "Chiudi",
          });
          this.$router
              .replace("/#")
              .catch((error) => {
                error;
              })
              .then(() => {
                this.isLoading = false;
              })
              .catch((err) => {
                this.isLoading = false;
                console.log(err);
              });
        }
      }catch (e) {
        this.isLoading = false
        await this.$bvModal.msgBoxOk("Errore nella login. Riprovare.", {
          title: "Errore Login",
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        });
      }

    },

    async AccettaTermini(){
      const response = await Login.accettaTermini()
      if(response.status === 200){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore " + response.data.code,
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {})
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
      } else {
        router.push({
          name: "Prenota",
          query: { authToken: response.data.access_token },
        });
      }
    }
  },
};
</script>
<style>
@import "../../assets/custom/style.css";

.frameTermini{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
