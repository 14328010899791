<template>
  <div>
    <b-tabs content-class="my-15" fill>
      <b-tab active title="Prestazioni Sanitarie Private">
        <div>
          <prenotazione-privata :assistito="jsonData.assistito"></prenotazione-privata>
        </div>
      </b-tab>
      <!--<b-tab title="Prenotazione con NRE">
        <prenotazione-nre @getInfoUtente="getInfoUtente"></prenotazione-nre>
      </b-tab>-->
    </b-tabs>
  </div>
</template>
<script>
import prenotazionePrivata from "../privata/prenotazionePrivata.vue";
//import PrenotazioneNre from "../nre/prenotazioneNre.vue";

export default {
  components: {
    //PrenotazioneNre,
    prenotazionePrivata,
  },
  data() {
    return {
      jsonData: {
        assistito: {
          provinciaResidenza: null,
        },
      },
    };
  },
  computed: {},
  mounted: function () {},
  methods: {
    getInfoUtente(item) {
      let me = this;
      me.jsonData.assistito = item;
    },
  },
};
</script>

<style scoped>
.nav-link {
  color: #01803c !important;
}
.nav-tabs {
  background-color: #01803c !important;
}
</style>
