import axios from "axios";
const pathResourceLoginWso2 = "https://auth.ak12srl.it/token";
export default {
  methods: {
    loginWso2: function () {
      try {
        const params = new URLSearchParams();
        params.append("grant_type", "client_credentials");
        axios
          .post(pathResourceLoginWso2, params, {
            auth: {
              username: process.env.VUE_APP_CONSUMER_KEY,
              password: process.env.VUE_APP_CONSUMER_SECRET,
            },
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            localStorage.setItem("tokenWSO2", response.data.access_token);
            return response.data.access_token;
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (ex) {
        console.log(ex);
      }
    },
    /*errorAuthWso2: function (vueInstance, responseWso2) {
      console.log(responseWso2)
      if (responseWso2.status === 401) {
        vueInstance.$bvModal.msgBoxOk("Effettua nuovamente l'accesso.", {
          title: "Sessione scaduta",
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
          .then(() => {
            vueInstance.$router.replace("/").catch((err) => {
              err;
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else {
        vueInstance.$bvModal.msgBoxOk("Si è verificato un errore.", {
          title: "Errore",
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
          .then(() => {
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },*/
    strutturaPineta(struttura) {
      return struttura === "100001"
      ? "Catomaior"
      : struttura === "150145"
      ? "Santa Rita"
      : struttura === "150909"
      ? "Villa Bianca"
      : struttura === "150423"
      ? "Padre Pio"
      : "Villa Esther";
    }
  }
}
