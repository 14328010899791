import BaseService from "./BaseService";

export default class ListaAppuntamenti extends BaseService {

    static async getListaAppuntamenti(idStruttura, cf){
        return await this.perform({
            url: '/catomaior/appuntamenti/'+ idStruttura,
            method: 'post',
            headers: {},
            body: {codiceFiscale: cf}
        })
    }

    static async cedolaPrenotazione(idStruttura, body){
        return await this.perform({
            url: '/catomaior/cedolaprenotazione/'+idStruttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async deletePrenotazionePrivata(idStruttura, body){
        return await this.perform({
            url: '/catomaior/privata/prenotazione/annulla/' + idStruttura,
            method: 'post',
            headers:{},
            body: body
        })
    }
}
