import BaseService from "./BaseService";

export default class Utenti extends BaseService {

    static async getDatiUtente(){
        return await this.perform({
            url: '/cittadini/infoAccount/me',
            method: 'get',
            header: {}
        })
    }

    static async modificaUtente(body){
        return await this.perform({
            url: '/cittadini/infoAccount/',
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async eliminaUtente(cf, password){
        return await this.perform({
            url: '/cittadini/infoAccount/elimina',
            method: 'post',
            headers: {},
            body: {cf: cf, password: password}
        })
    }

    static async recuperoInfoUtente(idStruttura){
        return await this.perform({
            url: '/cittadini/infoAccount/' + idStruttura,
            method: 'get',
            headers: {}
        })
    }
}
