import BaseService from "./BaseService";

export default class PrenotazioniPrivate extends BaseService {

    static async ricercaPrestazione(struttura, prestazione, medico){
        return await this.perform({
            url: '/catomaior/privata/prenotazione/prestazioni/'+struttura,
            method: 'post',
            headers: {},
            body: {
                prestazione: prestazione,
                medico: medico
            }
        })
    }

    static async ricercaPrimaDisponibilita(struttura, body){
        return await this.perform({
            url: '/catomaior/privata/prenotazione/disponibilita/'+ struttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

    static async confermaDisponibilita(struttura, body){
        return await this.perform({
            url: '/catomaior/privata/prenotazione/conferma/'+ struttura,
            method: 'post',
            headers: {},
            body: body
        })
    }

}
