<template>
  <div>
    <div class="mt-15 container card_container">
      <h3 class="mb-5 titolo"><i class="fas fa-address-book"></i> Lista Assistiti</h3>
      <b-container class="bv-example-row">
        <template>
          <b-form @submit.prevent="onSubmit">
            <b-row>
              <b-col sm="12" lg="6">
                <b-form-input v-model="filtro.cognome" style="margin-top: 5px" type="search" id="filterInput" placeholder="Cerca per Cognome"></b-form-input>
              </b-col>
              <b-col sm="10" lg="4">
                <b-button type="submit" variant="outline-primary" class="mx-2"><i class="fas fa-search"></i> Cerca</b-button>
                <b-button type="reset" v-on:click="onReset" variant="outline-danger" class="my-2"><i class="fas fa-times"></i> Resetta</b-button>
              </b-col>
              <b-col sm="2">
                <b-button variant="primary" class="my-2" @click="onAggiungiContatto"><i class="fas fa-plus"></i> Aggiungi Assistito</b-button>
              </b-col>
            </b-row>
            <b-row> </b-row>
          </b-form>
        </template>
        <template>
          <div class="b-table-sticky-header mt-10">
            <b-table
              sticky-header
              ref="table"
              id="tbl"
              :filter="filtro"
              responsive
              stacked="lg"
              striped
              hover
              :items="contatti"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              sort-icon-left
              head-variant="light"
              class="sa-b-table"
            >
              <template v-slot:cell(titolo)="{ item }">
                <router-link class="sa-edit-link" :to="'/agende/view/' + item.id">{{ item.titolo }}</router-link>
              </template>
              <template v-slot:cell(actions)="row">
                <b-button size="sm" class="no-text mx-1" variant="outline-primary" @click="onEdit(row.item)">
                  <b-icon icon="pencil" variant="outline-primary" />
                </b-button>

                <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="onView(row.item)">
                  <b-icon icon="search" variant="outline-secondary" />
                </b-button>

                <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="onDelete(row.item)"><b-icon icon="trash" variant="outline-danger"></b-icon></b-button>
              </template>
            </b-table>
          </div>
        </template>
        <template>
          <b-row>
            <b-col cols="6">
              <strong class="text-primary"> Sono presenti {{ rows }} Assistiti</strong>
            </b-col>
            <b-col cols="6">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
            </b-col>
          </b-row>
        </template>
      </b-container>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#11A7BE" loader="dots" :height="120" :width="120"></loading>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Contatti from "@/services/Contatti";
export default {
  components: { Loading },
  data() {
    return {
      pathResourceDelete: "/api/v2.0/appecupt/auth/eliminaContatto",
      currentPage: 1,
      perPage: 10,
      filtro: { cognome: "" },
      fields: [
        {
          label: "NOME",
          key: "nome",
        },
        {
          label: "COGNOME",
          key: "cognome",
        },
        {
          label: "CODICE FISCALE",
          key: 'codiceFiscale'
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 10rem",
        },
      ],
      contatti: [],
      token: null,
      tokenLogin: null,
      isLoading: false,
    };
  },
  computed: {
    rows() {
      return this.contatti.length;
    },
  },
  mounted: function () {
    this.token = localStorage.getItem("tokenWSO2");
    this.tokenLogin = localStorage.getItem("loginData");
    this.loadContatti();
  },
  methods: {
    async loadContatti() {
      this.contatti = []
      const response = await Contatti.getDatiContatti()
      if(!response.data.error){
        for(let i=0; i<response.data.data.length; i++)
          this.contatti = response.data.data
      }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
        this.$bvModal
            .msgBoxOk(response.data.msg, {
              title: "Errore",
              size: "md",
              okVariant: "outline-danger",
              headerClass: "vv-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
              okTitle: "Chiudi",
            })
            .then(() => {
              this.isLoading = false;
              localStorage.clear();
              window.location.assign('/')
              /*this.$router.replace("/#").catch((err) => {
                err;
              });*/
            })
            .catch(() => {
              this.isLoading = false;
            });
      } else {
        this.$bvModal.msgBoxOk(response.data.msg, {
          title: "Errore " + response.data.error,
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
            .then(() => {})
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
      }
    },
    onEdit(item) {
      this.$emit("onEdit", item);
    },
    async onDelete(item) {
      this.$bvModal
          .msgBoxConfirm("E' sicuro di voler eliminare il contatto?", {
            title: "Elimina Contatto",
            footerClass: "p-2",
            centered: true,
          })
          .then(async (res) => {
            if (res){
              const response = await Contatti.eliminaContatto(item.codiceFiscale)
              if(!response.data.error){
                this.$bvModal
                    .msgBoxOk(response.data.data, {
                      title: "Contatto Eliminato",
                      size: "md",
                      okVariant: "outline-primary",
                      headerClass: "vv-msg-header-primary",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                      okTitle: "Chiudi",
                    })
                    .then(() => {
                      this.isLoading = false;
                      this.loadContatti();
                    })
                    .catch((err) => {
                      this.isLoading = false;
                      console.log(err);
                    });
                await this.loadContatti();
              }else if (response.data.code === 'ERR_CHECK_AUTH_2'){
                this.$bvModal
                    .msgBoxOk(response.data.msg, {
                      title: "Errore",
                      size: "md",
                      okVariant: "outline-danger",
                      headerClass: "vv-msg-header-danger",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                      okTitle: "Chiudi",
                    })
                    .then(() => {
                      this.isLoading = false;
                      localStorage.clear();
                      window.location.assign('/')
                      /*this.$router.replace("/#").catch((err) => {
                        err;
                      });*/
                    })
                    .catch(() => {
                      this.isLoading = false;
                    });
              } else {
                this.$bvModal
                    .msgBoxOk(response.data.error, {
                      title: "Errore",
                      size: "md",
                      okVariant: "outline-danger",
                      headerClass: "vv-msg-header-danger",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                      okTitle: "Chiudi",
                    })
                    .then(() => {})
                    .catch((err) => {
                      this.isLoading = false;
                      console.log(err);
                    });

                this.isLoading = false;
                this.loadContatti();
              }
            }
          })
          .catch(() => {
            this.loadContatti();
          });
    },
    onView(item) {
      this.$emit("onView", item);
    },
    onSubmit() {
      this.contatti = this.contatti.filter((element) => element.cognome.toUpperCase().includes(this.filtro.cognome.toUpperCase()));
    },
    onReset() {
      this.contatti = [];
      this.loadContatti();
    },
    onAggiungiContatto() {
      this.$emit("onAggiungi");
    },
  },
};
</script>
