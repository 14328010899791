<template>
  <b-modal
    size="lg"
    id="modalEliminaAccount"
    title="Conferma Eliminazione"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    ref="modalEliminaAccount"
    centered
    @ok="onElimina"
    okTitle="Conferma"
    cancelTitle="Annulla"
  >
    <b-row>
      <b-col>
        <strong>Password</strong>
        <VuePassword v-model="password" id="password1" :disableStrength="true" v-on:keyup.enter="onElimina" />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import VuePassword from "vue-password";
export default {
  components: { VuePassword },
  props: {
    nonErogabili: Array,
  },
  data() {
    return {
      password: "",
    };
  },
  methods: {
    show() {
      let me = this;
      me.$refs["modalEliminaAccount"].show();
    },
    onElimina() {
      let me = this;
      me.$emit("onElimina", me.password);
      me.password = ''
    },
  },
};
</script>
