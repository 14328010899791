import BaseService from "./BaseService";

export default class Login extends BaseService {

    static async login(idStruttura, credenziali){
        return await this.perform({
            url: '/cittadini/login/' + idStruttura,
            method: 'post',
            body: credenziali,
            header: {}
        })
    }

    static async accettaTermini(){
        return await this.perform({
            url: '/cittadini/terminiServizio/accetta',
            method: 'post',
            header: {}
        })
    }
}
