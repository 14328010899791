<template>
  <div>
    <b-table striped hover :items="esamiDisponibili" :fields="fields" responsive stacked="lg">
      <template v-slot:cell(dataEora)="row">
        <label>{{ row.item.data }} {{ row.item.ora }}</label>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  props: {
    esamiDisponibili: Array,
  },
  data() {
    return {
      fields: [
        {
          label: "Prestazione",
          key: "desPrestazione",
        },
        {
          label: "Data Appuntamento",
          key: "dataEora",
        },
        {
          label: "Reparto",
          key: "reparto.desReparto",
        },
      ],
    };
  },
};
</script>
