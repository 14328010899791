import axios from "axios";
import CryptoJS from "crypto-js";

export default class BaseService {
    static async perform (request) {
        if (request.params == null)
            request.params = { client: 'webEcupt' }
        else
            request.params.client = 'webEcupt'
        const config = {
            //baseURL: process.env.VUE_APP_URL_BACKEND + "/api",
            url: "/api" + request.url,
            method: request.method,
            headers: request.headers,
            data: request.body,
            params: request.params
        };

        axios.interceptors.request.use(
            async config => {
                config.headers['Authorization'] = 'Bearer ' + localStorage.getItem("tokenWSO2");
                config.headers['Content-Type'] = 'application/json';
                //config.headers['versioneapp'] = process.env.VUE_APP_VERSIONE;
                config.headers['Accept-Version'] = '1.0.0';
                config.headers['access-token'] = localStorage.getItem('loginData');
                config.headers['struttura'] = '140000';
                return await config;
            },
            error => {
                Promise.reject(error)
            });

        axios.interceptors.response.use(function (response) {
            if(response.data.code === "ERR_CHECK_AUTH_2"){
                localStorage.clear();
                window.location.href = "/"
                alert("La sessione è scaduta.");
                return
            }
            return response;

        }, async function (error) {
            if (error.response.status === 401 && !error.config._retry) {
                error.config._retry = true;
                let tokenApi = await getTokenApi();
                sessionStorage.setItem('apiToken', tokenApi.data.data.access_token)
                window.location.reload();
                return await axios(config);
            }
        });
        return await axios(config);
    }
}

async function getTokenApi() {
    return axios({
        baseURL: process.env.VUE_APP_URL_BACKEND,
        url: "/api/v2.0/appecupt/auth/token",
        method: "POST",
        headers: {
            "client_credentials": CryptoJS.AES.encrypt(process.env.VUE_APP_CLIENT_CREDENTIALS, process.env.VUE_APP_PASSPHRASE).toString(),
        }
    });
}
