import BaseService from "./BaseService";

export default class Contatti extends BaseService {

    static async getDatiContatti(){
        return await this.perform({
            url: '/cittadini/contatti/',
            method: 'get',
            header: {}
        })
    }

    static async aggiungiContatto(body){
        return await this.perform({
            url:'/cittadini/contatti/',
            method: 'post',
            body: body,
            header: {}
        })
    }

    static async eliminaContatto(cf){
        return await this.perform({
            url: '/cittadini/contatti/',
            method: 'delete',
            params: {cf: cf},
            headers: {}
        })
    }

    static async modificaContatto(cfContatto, body){
        return await this.perform({
            url:'/cittadini/contatti/modifica',
            method: 'post',
            params:{cfContatto: cfContatto},
            body: body,
            headers: {}
        })
    }

    static async controlloUtenza(body){
        return await this.perform({
            url: '/cittadini/contatti/controlloDati/anagrafica',
            method: 'POST',
            body: body
        })
    }
}
