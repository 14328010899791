<template>
  <div>
    <navbar2></navbar2>
    <div class="mt-15 container card_container">
      <b-container class="bv-example-row">
        <b-card bg-variant="light">
          <div class="mb-5 titolo">
            <h3 class="mb-5 primary">Recupera Username</h3>
          </div>
          <b-container class="bv-example-row pt-lg-6">
            <b-row class="text-center">
              <b-col sm class="mb-7">
                <strong><span class="text-center">Inserisci il codice PIN</span></strong>
                <div class="mt-5"><PincodeInput v-model="pin" placeholder="" :length="5" /></div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-container>
    </div>
  </div>
</template>
<script>
import navbar2 from "../navbar/navbar2.vue";
import PincodeInput from "vue-pincode-input";
export default {
  components: { PincodeInput, navbar2 },
  data() {
    return {
      tokenLogin: null,
      pin: "",
    };
  },
  mounted: function () {},
  methods: {},
};
</script>
