<template>
  <div>
    <div class="separator border-4 my-3"></div>
    <b-container class="bv-example-row">
      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px"><strong>Nome</strong></div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="nome"
            placeholder="Nome"
            value=""
            v-model="jsonData.nome"
            style="text-transform: uppercase"
            disabled
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Cognome</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="cognome"
            placeholder="Cognome"
            value=""
            v-model="jsonData.cognome"
            style="text-transform: uppercase"
            disabled
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Codice fiscale</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="cf"
            placeholder="Codice Fiscale"
            value=""
            v-model="jsonData.codiceFiscale"
            style="text-transform: uppercase"
            disabled
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px"><strong>Data di nascita</strong></div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="datanascita"
            placeholder="Data di nascita"
            value=""
            v-model="jsonData.dataNascita"
            locale="it"
            disabled
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Luogo nascita</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="comunenascita"
            placeholder="Comune di nascita"
            value=""
            v-model="jsonData.luogoDiNascita.descrizione"
            style="text-transform: uppercase"
            disabled
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Sesso</strong>
          </div>
          <b-form-select class="form-select" v-model="jsonData.sesso" :options="sessoOptions" disabled> </b-form-select>
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Stato civile</strong>
          </div>
          <b-form-select :value="null" class="form-select" :state="validateStatoCivile" v-model="jsonData.statoCivile" :options="statoCivileOptions">
            <template #first>
              <b-form-select-option :value="null" disabled>Seleziona Stato Civile</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Provincia di residenza</strong>
          </div>
          <b-form-select
            class="form-select"
            v-model="jsonData.provinciaResidenza"
            :options="provinciaResidenzaOptions"
            :value="null"
            :state="validateProvinciaResidenza"
            value-field="value"
            text-field="text"
            @change="getSelectedProvinciaResidenza"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong> Comune di residenza</strong>
          </div>
          <b-form-select
            class="form-select"
            v-model="comune"
            value-field="value"
            :state="validateComuneResidenza"
            :options="comuniResidenzaOptions"
            text-field="text"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>Seleziona Comune di residenza</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Indirizzo di residenza</strong>
          </div>
          <b-form-input
              type="text"
              v-model="jsonData.indirizzoResidenza"
              :state="validateIndirizzoResidenza"
              :value="null"
              value-field="value"
              text-field=""
              style="text-transform: uppercase"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Cellulare</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="provincianascita"
            placeholder="Cellulare"
            value=""
            required
            maxlength="10"
            :state="validationCellulare"
            v-model="jsonData.numeroCellulare"
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Telefono fisso</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="provincianascita"
            placeholder="Telefono fisso"
            value=""
            required
            maxlength="10"
            :state="validationFisso"
            v-model="jsonData.numeroFisso"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Ultime 5 cifre della tessera sanitaria</strong>
          </div>
          <input
            type="text"
            class="form-control form-control-solid"
            name="provincianascita"
            value=""
            v-model="jsonData.numeroTs"
            disabled
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px"><strong>Data di scadenza</strong></div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="datascadenza"
            value=""
            v-model="jsonData.scadenzaTs"
            size="sm"
            locale="it"
            disabled
          />
        </b-col>
      </b-row>
      <b-row>
        <b-form-checkbox id="checkbox-1" v-model="jsonData.terminiServizio" name="checkbox-1" :value="true" :unchecked-value="false">
          <span class="ml-2">
            Prima di continuare accettare
            <a :href="linkTerminiServizio" target="_blank" class="link-termini">termini e condizioni</a></span
          >
        </b-form-checkbox>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import Utils from "@/services/Utils";
export default {
  props: {
    jsonData: {
      type: Object,
      default: function () {
        return {
          statoCivile: null,
          numeroCellulare: "",
          numeroFisso: "",
          provincia: "",
          provinciaResidenza: null,
          comuneResidenza: null,
          indirizzoResidenza: "",
          luogoDiNascita: "",
          nome: "",
          cognome: "",
          codiceFiscale: "",
          dataNascita: "",
          sesso: "",
          terminiServizio: null,
          scadenzaTs: "",
          numeroTs: ""
        };
      },
    },
  },
  data() {
    return {
      comune: null,
      statoCivileOptions: [],
      sessoOptions: [
        { value: "M", text: "MASCHIO" },
        { value: "F", text: "FEMMINA" },
      ],
      provinciaResidenzaOptions: [{ value: null, text: "Seleziona la Provincia", disabled: true }],
      comuniResidenzaOptions: [{ value: null, text: "Seleziona il comune", disabled: true }],
      linkTerminiServizio: "/resources/terminiServizio/cittadini.pdf",
      token: null,
      nazionalitaStraniera: false,
    };
  },
  computed: {
    scadenzaTesseraSanitaria: {
      get() {
        let me = this;
        let scadenzaTessera = Date(me.jsonData.scadenzaTs);
        return moment(scadenzaTessera).format("DD/MM/YYYY");
      },
      set(value) {
        return moment(value).format("DD/MM/YYYY");
      },
    },
    validationCellulare() {
      if(!this.jsonData.numeroCellulare && !this.jsonData.numeroFisso)
        return false
      const regexCellulare = /^3\d{8,9}$/;
      return !this.jsonData.numeroCellulare? true : regexCellulare.test(this.jsonData.numeroCellulare) ? true : false;
    },
    validationFisso() {
      if(!this.jsonData.numeroCellulare && !this.jsonData.numeroFisso)
        return false
      let regexFisso = /^0\d+$/;
      return !this.jsonData.numeroFisso? true : regexFisso.test(this.jsonData.numeroFisso) ? true : false;
    },
    validateComuneResidenza() {
      return !this.comune ? false : true;
    },
    validateIndirizzoResidenza() {
      return !this.jsonData.indirizzoResidenza ? false : true;
    },
    validateStatoCivile() {
      return !this.jsonData.statoCivile ? false : true;
    },
    validateProvinciaResidenza() {
      return !this.jsonData.provinciaResidenza ? false : true;
    }
  },
  mounted: function () {
    let me = this;
    me.token = localStorage.getItem("tokenWSO2");
    me.loadListaProvince();
    me.loadStatoCivile();
    // L'utente che sta tentando di registrarsi è nato all'estero
    if (this.jsonData.codiceFiscale[11] === 'Z')
      this.nazionalitaStraniera = true;
  },
  watch:{
    "comune":function (){
      this.jsonData.comuneResidenza = this.comune
    }
  },
  methods: {
    async loadListaProvince() {
      const response = await Utils.getProvince()
      if(!response.data.error){
        for (let i=0; i<response.data.data.length; i++){
          this.provinciaResidenzaOptions.push({
            disabled: false,
            value: response.data.data[i],
            text: response.data.data[i].label.toUpperCase()
          })
        }
      } else {
        console.log(response.data.msg)
      }
    },
    async loadStatoCivile() {
      const response = await Utils.getStatoCivile()
      if(!response.data.error){
        for(let i=0; i<response.data.data.length; i++){
          this.statoCivileOptions.push({
            disabled: false,
            value: response.data.data[i],
            text: response.data.data[i].descrizione
          })
        }
      } else {
        console.log(response.data.msg)
      }
    },
    async getSelectedProvinciaResidenza() {
      this.comuniResidenzaOptions = [];
      this.jsonData.comuneResidenza = null
      const response = await Utils.getComuni(this.jsonData.provinciaResidenza.value, null)
      if(!response.data.error){
        for(let i=0; i< response.data.data.length; i++){
          this.comuniResidenzaOptions.push({
            disabled: false,
            value: response.data.data[i],
            text: response.data.data[i].descrizione.toUpperCase()
          })
        }
      } else {
        console.log(response.data.msg)
      }
    },
  },
};
</script>

<style></style>
